import React, {useEffect, useState} from 'react';
import './style.scss'
import {useSelector} from "react-redux";

const FunctionalButton = ({
                              text,
                              icon,
                              isGradient = false,
                              rightText,
                              disabled,
                              onClick,
}) => {

    const truncateString = (s) => {
        if (s.length > 9) {
            return s.slice(0, 5) + "..." + s.slice(-4);
        } else {
            return s;
        }
    }

    const {isMob} = useSelector(state => state.adaptive)

    return (
        <div className="function-button_wrap">
            <button
                disabled={disabled}
                className={`function-button_wrap-main ${isGradient && 'grad'}`}
                onClick={onClick}
            >
                {!!icon &&
                <img src={icon} alt=""/>
                }
                <h5>
                    {text}
                </h5>
                {!!rightText &&
                <div className={'function-button_wrap-main-right'}>
                    <h5>
                        {isMob ? rightText : truncateString(rightText)}
                    </h5>
                </div>
                }
            </button>
        </div>
    );
};

export default FunctionalButton;