import React from "react";

function LandingIcon({color = '#222342'}) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="none"
            viewBox="0 0 32 32"
        >
            <path
                fill={color}
                d="M14.4 0a14.4 14.4 0 100 28.8 14.4 14.4 0 000-28.8zM1.776 15.488h4.016c.064 1.456.267 2.902.608 4.32H2.944a12.576 12.576 0 01-1.168-4.32zm13.712-8.64V1.904a6.608 6.608 0 013.552 3.2c.328.555.618 1.133.864 1.728l-4.416.016zm5.152 2.16c.371 1.413.592 2.86.656 4.32h-5.808v-4.32h5.152zm-7.328-7.104v4.944H8.896c.247-.596.536-1.173.864-1.728a6.608 6.608 0 013.552-3.216zm0 7.104v4.32H7.52c.064-1.46.285-2.907.656-4.32h5.136zm-7.52 4.304H1.776a12.576 12.576 0 011.168-4.32H6.4a22.608 22.608 0 00-.608 4.32zm1.728 2.176h5.792v4.32H8.176a20.608 20.608 0 01-.656-4.32zm5.808 6.4v4.944a6.608 6.608 0 01-3.552-3.2 13.65 13.65 0 01-.864-1.728l4.416-.016zm2.16 4.944v-4.864h4.416a13.641 13.641 0 01-.864 1.728 6.608 6.608 0 01-3.552 3.2v-.064zm0-7.104v-4.32h5.792a20.614 20.614 0 01-.656 4.32h-5.136zm7.536-4.32h4.016a12.575 12.575 0 01-1.168 4.32H22.4c.336-1.392.54-2.811.608-4.24l.016-.08zm0-2.16a22.61 22.61 0 00-.624-4.24h3.456a12.583 12.583 0 011.168 4.32l-4-.08zm1.6-6.4H21.76a14.272 14.272 0 00-2.224-4.032 12.8 12.8 0 015.024 4.032h.064zM9.264 2.816A14.272 14.272 0 007.04 6.848h-2.8a12.8 12.8 0 015.024-4.032zm-5.04 19.2H7.04a14.273 14.273 0 002.224 4.032 12.8 12.8 0 01-5.024-4.08l-.016.048zm15.296 4.032a14.272 14.272 0 002.224-4.032h2.816a12.8 12.8 0 01-5.024 3.968l-.016.064z"
            />
        </svg>
    );
}

export default LandingIcon;
