import React, {useContext, useEffect, useState} from 'react';
import "./styles.scss"
import Button from "../../Common/Button";
import {useDispatch, useSelector} from "react-redux";
import {setChangeLoader, setSuccessModal} from "../../../redux/swapper";
import {Oval} from "react-loader-spinner";
import SuccessSwapIcon from "../../../assets/icons/SuccessSwapIcon";

const ModalSuccessSwap = () => {

    const dispatch = useDispatch()

    const {successModal} = useSelector(state => state.swapper)

    return (
        <>
            {successModal &&
            <div className="confirm-modal-loader">
                <div className="confirm-modal-loader-content">

                    <div className="confirm-modal-loader-content-title">
                        Транзакция отправлена
                    </div>

                    <div className="confirm-modal-loader-content-icon">
                        <SuccessSwapIcon/>
                    </div>

                    <div className="confirm-modal-loader-content-desc">
                        You approved
                    </div>

                    <Button
                        backColor={'#40A5F3'}
                        onClick={() => window.open(`https://devnet.explorer.decimalchain.com/transactions/${successModal}`, '_blank')}
                        text={'Смотреть на BscScan'}
                    />
                    <div style={{marginTop: 11}}/>
                    <Button
                        backColor={'#222342'}
                        onClick={() => dispatch(setSuccessModal(''))}
                        text={'Закрыть'}
                    />
                </div>
            </div>
            }
        </>
    );
}

export default ModalSuccessSwap;