import React, {useState} from 'react';
import './style.scss'
import {Link} from "react-router-dom";

const SwaperNav = () => {

    const navItems = [
        {
            id: 1,
            href: '/main/swap',
            name: 'Swap'
        },
        {
            id: 2,
            href: '/main/pools',
            name: 'Pools'
        },
    ]

    return (
        <div className="swaper-nav">
            {
                navItems.map(item =>
                    <Link className={`swaper-nav-tab ${location.pathname.includes(item.href) ? 'active' : ''}`}
                          to={item.href}>
                        {item.name}
                    </Link>
                )
            }
        </div>
    );
};

export default SwaperNav;