import React from 'react';
import BackButton from "../../../components/Common/BackButton";
import ShortCoinCard from "../../../components/Main/ShortCoinCard";
import Button from "../../../components/Common/Button";
import MobHeader from "../../../components/Main/MobHeader";
import Wrapper from "../../../components/Main/Wrapper";
import {useNavigate} from "react-router-dom";
import CircleArrowToBottom from "../../../assets/icons/CircleArrowToBottom";
import {useConfirm} from "../../../controllers/ModalConfirm";
import CircleArrowToTop from "../../../assets/icons/CircleArrowToTop";

const TakeLiquidity = () => {

    const navigate = useNavigate();
    const confirmU = useConfirm();

    const onTakeLiquidity = () =>{
        confirmU({
            isVisible: true,
            title: 'Транзакция отправлена',
            img: <CircleArrowToTop/>,
            text: 'С вашего кошелька будет списано',
            textExtra: '100 CRYG',
            agreeText: 'Подтвердить',
            dontAgreeText: 'Отменить',
            onPress: () => console.log("123")
        })
    }

    return (
        <div className="swaper-wrapper">
            <MobHeader/>
            <Wrapper minHeight={350}>
                <div>
                    <div className="addLiquidity">
                        <BackButton
                            onPress={() => navigate(-1)}
                            title={'Добавить ликвидность'}
                        />

                        <div style={{marginTop: 15}}/>

                        <ShortCoinCard
                            bcgColor={'#282844'}
                            name={'Вы покупаете'}
                            balance={0}
                            isWithhMax={false}
                            token={'1'}
                            tokenName={'CRYG'}
                            count={'~$333.73'}
                            onPress={() => navigate('/main/pools/choose-token')}
                        />

                        <div style={{marginTop: 10}}/>

                        <ShortCoinCard
                            bcgColor={'#4b4a61'}
                            name={'Вы покупаете'}
                            balance={0}
                            isWithhMax={false}
                            token={'0'}
                            tokenName={'DELL'}
                            count={'~$333.73'}
                            onPress={() => navigate('/main/pools/choose-token')}
                        />

                        <div style={{marginTop: 30}}/>

                        <Button
                            text={'Добавить'}
                            onClick={onTakeLiquidity}
                        />
                    </div>
                </div>
            </Wrapper>
        </div>
    );
};

export default TakeLiquidity;