import {useEffect} from "react";
import {useSelector, useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {configureTransport} from '../../api/transport';
import {get, useGet} from '../../api/request';
import {setTokens, setUser} from '../../redux/auth';

export const useFetchUser = () => {

}


const AuthController = ({children}) => {

    return children;
}

export default AuthController;
