import React, {useState} from 'react';
import BackButton from "../../../components/Common/BackButton";
import CoinCard from "../../../components/Main/CoinCard";
import BigArrowToBottom from "../../../assets/icons/BigArrowToBottom";
import ExtraInfo from "../../../components/Main/ExtraInfo";
import CheckBox from "../../../components/Common/CheckBox";
import Button from "../../../components/Common/Button";
import {useNavigate} from "react-router-dom";
import MobHeader from "../../../components/Main/MobHeader";
import Wrapper from "../../../components/Main/Wrapper";
import ChooseTokenModal from "../../../components/Main/ChooseTokenModal";
import {useSelector} from "react-redux";

const СonfirmSwapper = () => {

    const navigate = useNavigate();
    const {isMob} = useSelector(state => state.adaptive)

    const [checkBox, setCheckBox] = useState(false)

    const [modalOpenToken, setModalOpenToken] = useState(false);

    const openModal = () => {
        setModalOpenToken(true);
    };

    const closeModal = () => {
        setModalOpenToken(false);
    };

    return (
        <div className="swaper-wrapper">
            <MobHeader/>
            <Wrapper>
                <div>
                    <div className="swaper-confirm ">
                        <BackButton
                            onPress={() => navigate(-1)}
                            title={'Выберите токен'}
                        />

                        <div style={{marginTop: 15}}/>

                        <div className="swaper-cards">
                            <CoinCard
                                name={'Вы покупаете'}
                                balance={100}
                                isWithhMax={true}
                                token={'1'}
                                tokenName={'CRYG'}
                                count={'~$333.73'}
                                onPress={() => isMob ? openModal() : navigate('/main/pools/add-liquidity')}
                            />
                            <div className="swaper-cards-arrow">
                                <BigArrowToBottom/>
                            </div>
                            <CoinCard
                                bcgColor={'#4b4a61'}
                                name={'Вы покупаете'}
                                balance={0}
                                isWithhMax={false}
                                token={'0'}
                                tokenName={'DELL'}
                                count={'~$333.73'}
                                onPress={() => isMob ? openModal() : navigate('/main/pools/add-liquidity')}
                            />
                        </div>
                        <div style={{marginTop: 20}}/>

                        <ExtraInfo
                            keyText={'1 CRYG цена'}
                            valueText={'~$331.62'}
                            valueColor={'#d9d7e8'}
                            extraValue={'0.076452793 DEL'}
                        />
                        <ExtraInfo
                            keyText={'1 DEL цена'}
                            valueText={'~$331.62'}
                            valueColor={'#d9d7e8'}
                            extraValue={'0.076452793 USDC'}
                        />
                        <ExtraInfo
                            keyText={'Минимально допустимо'}
                            valueColor={'#d9d7e8'}
                            valueText={'0.076452793 CRYG'}
                        />
                        <ExtraInfo
                            changeText={"Изменить"}
                            valueColor={'#d9d7e8'}
                            keyText={'Макс.проскальзывание'}
                            valueText={'1%'}
                        />
                        <ExtraInfo
                            changeText={"Изменить"}
                            keyText={'Цена газа'}
                            valueText={'Высокая'}
                            valueColor={'#5f5e70'}
                            extraValueColor={'#d9d7e8'}
                            extraValue={'~$331.62'}
                        />
                        <div style={{marginTop: 20}}/>

                        <CheckBox
                            value={checkBox}
                            onChange={() => setCheckBox(!checkBox)}
                            text={'Получить DEL на другой кошелек'}
                        />
                        <div style={{marginTop: 20}}/>

                        <Button
                            text={'Подтвердить обмен'}
                            height={56}
                            onClick={() => {
                            }}
                        />

                    </div>
                </div>
            </Wrapper>
            <ChooseTokenModal isOpen={modalOpenToken} onClose={closeModal}/>
        </div>
    );
};

export default СonfirmSwapper;