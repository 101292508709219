import {
	createStore,
	combineReducers,
	applyMiddleware,
} from 'redux';
import {
	persistStore,
	persistReducer,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage'
import auth from './redux/auth';
import adaptive from './redux/adaptive';
import swapper from './redux/swapper';

const appReducer = combineReducers({
	auth,
	adaptive,
	swapper,
});

const persistConfig = {
	key: 'root-Swaper',
	storage,
	whitelist: ['auth'],
};

const persistedReducer = persistReducer(persistConfig, appReducer);

const store = createStore(persistedReducer);

export default store;
export const persistor = persistStore(store);
