import React from 'react';
import { useSpring, animated } from 'react-spring';

const ExampleComponent = ({ targetValue }) => {
    const isFractional = !Number.isInteger(targetValue);

    const { value } = useSpring({
        from: { value: 0 },
        to: { value: targetValue },
        config: { duration: 1000 }
    });

    return (
        <span>
      <animated.span>
        {isFractional ? value.interpolate((val) => val.toFixed(1)) : value.interpolate((val) => Math.floor(val))}
      </animated.span>
    </span>
    );
};

export default ExampleComponent;