import React from 'react';
import {AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer} from 'recharts';
import {useSelector} from "react-redux";

const data = [
    {name: '0', CRYG: 20},
    {name: '500', CRYG: 500},
    {name: '1000', CRYG: 456},
    {name: '10000', CRYG: 1000},
    {name: '10000', CRYG: 2000},
    // Add more data points as needed
];


const CustomAreaChart = () => {

    const {isMob} = useSelector(state => state.adaptive)


    const gradientOffset = () => {
        const dataMax = Math.max(...data.map((item) => item.CRYG));
        const dataMin = Math.min(...data.map((item) => item.CRYG));
        const offset = (dataMax - dataMin) * 0.1; // Adjust the gradient offset as needed
        return dataMin - offset;
    };

    const off = gradientOffset();
    return (
        <ResponsiveContainer width="100%" height={300}>
            <AreaChart data={data} margin={{ left: -50, right: 20, top: 10, bottom: 0 }}>
                <defs>
                    <linearGradient id="colorGradient" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="#222342" stopOpacity={0.4}/>
                        <stop offset="95%" stopColor="#40A5F3" stopOpacity={0}/>
                    </linearGradient>
                </defs>
                <CartesianGrid strokeOpacity={0}/>
                <XAxis dataKey="name"/>
                <YAxis display="none"/>
                <Tooltip/>
                <Area type="monotone" dataKey="CRYG" fill="url(#colorGradient)" fillOpacity={1}/>
            </AreaChart>
        </ResponsiveContainer>
    );
};

export default CustomAreaChart;