import React from "react";

function EmailIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="none"
            viewBox="0 0 20 20"
        >
            <path
                fill="#fff"
                d="M5 3.75a2.5 2.5 0 00-2.5 2.5v.251L10 10.54l7.5-4.037V6.25a2.5 2.5 0 00-2.5-2.5H5zm12.5 4.171L10.296 11.8a.626.626 0 01-.592 0L2.5 7.921v5.829a2.5 2.5 0 002.5 2.5h10a2.5 2.5 0 002.5-2.5V7.921z"
            />
        </svg>
    );
}

export default EmailIcon;
