import React from 'react';

const WalletIcon = () => {
    return (
        <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6 9.16699H9.66667" stroke="white" strokeWidth="2" strokeLinecap="round"
                  strokeLinejoin="round"/>
            <path
                d="M19.5969 10.084H17.2118C15.5755 10.084 14.25 11.3151 14.25 12.834C14.25 14.3529 15.5764 15.584 17.2108 15.584H19.5969C19.6739 15.584 19.7115 15.584 19.7436 15.5822C20.2386 15.5519 20.6327 15.1862 20.6648 14.7269C20.6667 14.6976 20.6667 14.6618 20.6667 14.5912V11.0767C20.6667 11.0062 20.6667 10.9704 20.6648 10.9411C20.6318 10.4818 20.2386 10.1161 19.7436 10.0858C19.7115 10.084 19.6739 10.084 19.5969 10.084Z"
                stroke="white" strokeWidth="2"/>
            <path
                d="M19.718 10.0833C19.6465 8.36733 19.4173 7.315 18.6757 6.57433C17.6023 5.5 15.8735 5.5 12.4167 5.5H9.66671C6.20996 5.5 4.48112 5.5 3.40771 6.57433C2.33337 7.64775 2.33337 9.37658 2.33337 12.8333C2.33337 16.2901 2.33337 18.0189 3.40771 19.0923C4.48112 20.1667 6.20996 20.1667 9.66671 20.1667H12.4167C15.8735 20.1667 17.6023 20.1667 18.6757 19.0923C19.4173 18.3517 19.6474 17.2993 19.718 15.5833"
                stroke="white" strokeWidth="2"/>
            <path
                d="M6 5.50048L9.42375 3.2299C9.90524 2.91671 10.4673 2.75 11.0417 2.75C11.6161 2.75 12.1781 2.91671 12.6596 3.2299L16.0833 5.50048"
                stroke="white" strokeWidth="2"strokeLinecap="round"/>
            <path d="M16.9918 12.834H17.0027" stroke="white" strokeWidth="2" strokeLinecap="round"
                  strokeLinejoin="round"/>
        </svg>

    );
};

export default WalletIcon;