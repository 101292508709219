import "./index.scss";
import {Link, useNavigate} from "react-router-dom";

import {useLocation} from "react-router-dom";

const Nav = () => {
    const location = useLocation();

    const navElement = [
        {
            href: '/main',
            title: 'Swap&Pool'
        },
        // {
        //     href: '/lending',
        //     title: 'Лендинг'
        // },
        {
            href: '/faq',
            title: 'FAQ'
        },
        {
            href: '/feedback',
            title: 'Обратная связь'
        }
    ];

    return (

        <ul className="navbar">
            {
                navElement.map((item) =>
                    <li className="navbar__item">
                        <Link className={`navbar__link ${location.pathname.includes(item.href) ? 'active' : ''}`} to={item.href}>
                            {
                                item.title
                            }
                        </Link>
                    </li>
                )
            }
        </ul>

    );
};

export default Nav;
