import React from 'react';
import ArrowToLeft from "../../../assets/icons/ArrowToLeft";
import './style.scss'
const BackButton = ({title, onPress}) => {
    return (
        <div className="back-button">
            <div className="back-button-icon" onClick={onPress}>
                <ArrowToLeft/>
            </div>
            <div className="back-button-text">
                {title}
            </div>
            <div/>
        </div>
    );
};

export default BackButton;