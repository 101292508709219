import React, {useState} from 'react';
import './style.scss'
import Wrapper from "../../../components/Main/Wrapper";
import SwaperNav from "../../../components/Main/SwaperNav";
import Button from "../../../components/Common/Button";
import BigWallet from "../../../assets/icons/BigWallet";
import PoolsCategories from "../../../components/Main/PoolsCategories";
import PoolsItem from "../../../components/Main/PoolsItems";
import CRYGIcon from '../../../assets/img/CRYGIcon.svg'
import DELIconCycle from '../../../assets/img/DELIconCycle.svg'
import MobHeader from "../../../components/Main/MobHeader";
import {useNavigate} from "react-router-dom";
import ModalLiquidityAdd from "../../../components/Main/ModalLiquidityAdd";
import {useSelector} from "react-redux";


const Pools = () => {

    const navigate = useNavigate();
    const {isMob} = useSelector(state => state.adaptive)

    const [isConnectedWallet, setIsConnectedWallet] = useState(false)

    const [currentPoolCategory, setCurrentPoolCategory] = useState('Все пулы')

    const [poolsItems, setPoolsItems] = useState([
        {
            id: 1,
            from: 'DEL',
            to: 'CRYG',
            fromCount: '28 640',
            toCount: '28 640',
            annualProfitability: '0.24',
            profitabilityPerDay: '23',
            tradingVolume: '12'
        },
        {
            id: 2,
            from: 'DEL',
            to: 'CRYG',
            fromCount: '28 640',
            toCount: '28 640',
            annualProfitability: '0.24',
            profitabilityPerDay: '23',
            tradingVolume: '12'
        },
        {
            id: 3,
            from: 'DEL',
            to: 'CRYG',
            fromCount: '28 640',
            toCount: '28 640',
            annualProfitability: '0.24',
            profitabilityPerDay: '23',
            tradingVolume: '12'
        },
        {
            id: 4,
            from: 'DEL',
            to: 'CRYG',
            fromCount: '28 640',
            toCount: '28 640',
            annualProfitability: '0.24',
            profitabilityPerDay: '23',
            tradingVolume: '12'
        },
    ])


    const [modalOpen, setModalOpen] = useState(false);
    const openModal = () => {
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
    };

    return (
        <div className="swaper-wrapper">
            <MobHeader/>
            <Wrapper minHeight={350}>
                <div>
                    <SwaperNav/>

                    <div className="pools">
                        {isConnectedWallet
                            ?
                            <div className="pools-connected">
                                <Button
                                    fz={12}
                                    height={32}
                                    text={'Добавить ликвидность'}
                                    onClick={() => isMob ? openModal() : navigate('/main/pools/add-liquidity')}
                                />

                                <div style={{marginTop: 16}}/>

                                <PoolsCategories
                                    currentPoolCategory={currentPoolCategory}
                                    setCurrentPoolCategory={setCurrentPoolCategory}
                                />

                                <div style={{marginTop: 20}}/>

                                <div className="pools-connected-items">
                                    {
                                        poolsItems.map(item =>
                                            <PoolsItem
                                                item={item}
                                                onClick={() => navigate('/main/pools/confirm-pools')}
                                            />
                                        )
                                    }
                                </div>

                            </div>
                            :
                            <div className="pools-unconnected">
                                <Button
                                    fz={12}
                                    height={32}
                                    text={'Добавить ликвидность'}
                                    onClick={() => isMob ? openModal() : navigate('/main/pools/add-liquidity')}
                                />

                                <div className="pools-unconnected-info">
                                    <BigWallet/>
                                    <h4>
                                        Здесь появятся ваши активные
                                        позиции ликвидности в V3
                                    </h4>
                                </div>

                                <Button
                                    text={'Подключить кошелек'}
                                    onClick={() => setIsConnectedWallet(true)}
                                />
                            </div>

                        }
                    </div>
                </div>
            </Wrapper>

            <ModalLiquidityAdd isOpen={modalOpen} onClose={closeModal} />
        </div>
    );
};

export default Pools;