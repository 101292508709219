import React from 'react';

const CircleArrowToTop = () => {
    return (
        <svg width="117" height="117" viewBox="0 0 117 117" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_158_996)">
                <path
                    d="M58.5 102.375C34.2685 102.375 14.625 82.7317 14.625 58.5C14.625 34.2685 34.2685 14.625 58.5 14.625"
                    stroke="#4786FF" strokeWidth="7" strokeLinecap="round"/>
                <path
                    d="M58.5 14.625C82.7317 14.625 102.375 34.2685 102.375 58.5C102.375 82.7317 82.7317 102.375 58.5 102.375"
                    stroke="#4786FF" strokeWidth="7" strokeLinecap="round"/>
                <path d="M58.5 78V41.4375" stroke="#4786FF" strokeWidth="7" strokeLinecap="round"/>
                <path d="M75.5625 58.5L58.5 41.4375L41.4375 58.5" stroke="#4786FF" strokeWidth="7"
                      strokeLinecap="round"/>
            </g>
            <defs>
                <filter id="filter0_d_158_996" x="3.125" y="3.125" width="110.75" height="110.75"
                        filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                   result="hardAlpha"/>
                    <feOffset/>
                    <feGaussianBlur stdDeviation="4"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0.25098 0 0 0 0 0.647059 0 0 0 0 0.952941 0 0 0 1 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_158_996"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_158_996" result="shape"/>
                </filter>
            </defs>
        </svg>

    );
};

export default CircleArrowToTop;