import './styles.scss';
import Loader from "../Loader";
import {Oval} from 'react-loader-spinner'

const Button = ({
                    disabled,
                    startIcon,
                    text,
                    backColor = '#40A5F3',
                    isLoading,
                    onClick,
                    height = 56,
                    fz = 16,
                }) => (
    <button
        className="button"
        onClick={onClick}
        disabled={isLoading || disabled}
        style={{
            height: height,
            backgroundColor: backColor,
        }}
    >
        <div className="startIcon">
            {startIcon &&
            startIcon
            }
        </div>
        <p style={{
            fontSize: fz,
        }}>
            {text}
        </p>
        {isLoading &&
        <div className="button-loader">
            <Oval
                strokeWidth={5}
                strokeWidthSecondary={5}
                secondaryColor={'#3F4157'}
                height="20"
                width="20"
                color="#fff"
            />
        </div>
        }
    </button>
);

export default Button;
