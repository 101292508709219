import React from 'react';
import './style.scss'
import ArrowToRight from "../../../assets/icons/ArrowToRight";
import {Link} from "react-router-dom";

const System = () => {
    return (
        <div className="system">

            <Link to={'/system/user-agreements'}>
                <div className="system-item">
                    <h4>
                        Пользовательское соглашение
                    </h4>
                    <ArrowToRight/>
                </div>
            </Link>
            <Link to={'/system/privacy-policy'}>
                <div className="system-item">
                    <h4>
                        Условия обработки данных
                    </h4>
                    <ArrowToRight/>
                </div>
            </Link>
        </div>
    );
};

export default System;