import React, {useEffect, useState} from 'react';
import './style.scss'
import Wrapper from "../../../components/Main/Wrapper";
import SwaperNav from "../../../components/Main/SwaperNav";
import Button from "../../../components/Common/Button";
import CoinCard from "../../../components/Main/CoinCard";
import BigArrowToBottom from "../../../assets/icons/BigArrowToBottom";
import WarningIcon from "../../../assets/icons/WarningIcon";
import WalletIcon from "../../../assets/icons/WalletIcon";
import ExtraInfo from "../../../components/Main/ExtraInfo";
import WarningBlock from "../../../components/Main/WarningBlock";
import MobHeader from "../../../components/Main/MobHeader";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import ChooseTokenModal from "../../../components/Main/ChooseTokenModal";
import ModalComfirmSwap from "../../../components/Main/ModalComfirmSwap";
import {useSwapper} from "../../../hooks/useSwapper";
import {get} from '../../../api/request'
import {setChangeLoader, setMainCount, setSuccessModal, setUserBalance} from "../../../redux/swapper";
import {useDebounce} from "use-debounce";
import {getCostPerOne} from "../../../help/getCostPerOne";
import {useConfirm} from "../../../controllers/ModalConfirm";
import CircleArrowToBottom from "../../../assets/icons/CircleArrowToBottom";
import ModalLoaderSwapper from "../../../components/Main/ModalLoaderSwapper";
import ModalSuccessSwap from "../../../components/Main/ModalSuccessSwap";
import {decimalChainId, useSocket} from "../../../controllers/SocketProvider";
import ModalError from "../../../components/Main/ModalError";

const Swap = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch()
    const {isMob} = useSelector(state => state.adaptive)
    const {wallet} = useSelector(state => state.auth)
    const {
        mainToken,
        secondaryToken,
        userBalance,
        tokens,
        dataForChange,
        changeLoader,
        sendHash,
        address
    } = useSelector(state => state.swapper)

    const {
        hasProvider,
        updateWallet,
        tokenSwap,
        tokenCanSwap,
        getTokenUpdate,
        handleConnect,
        changeNetwork,
    } = useSocket()

    const {
        sendTransaction,
        sendTransactionToken
    } = useSwapper(updateWallet)

    const [mainState, setMainState] = useState('main1')
    const [mainTokenCount, setMainTokenCount] = useState(1)
    const [value] = useDebounce(mainTokenCount, 1000);

    const [modalOpenToken, setModalOpenToken] = useState({
        isOpen: false,
        isMain: false,
    });

    const [modalConfirm, setModalConfirm] = useState(false);
    const closeModal = () => {
        setModalOpenToken({
            isOpen: false,
            isMain: false,
        });
    };
    const closeModalConfirm = () => {
        setModalConfirm(false);
    };

    useEffect(() => {
        tokenSwap({
            fromAddress: mainToken?.contractAddress || null,
            fromAmount: value,
            toAddress: secondaryToken?.contractAddress || null
        })
    }, [value, tokens, mainToken?.contractAddress, dataForChange])

    useEffect(() => {
        if (sendHash) {
            tokenCanSwap(sendHash)
        }
    }, [sendHash])

    const onSwap = () => {
        getTokenUpdate()
        if (mainToken?.contractAddress) {
            sendTransactionToken({
                amount: value,
                countFrom: mainTokenCount,
                countTo: dataForChange?.to?.amount,
                toContractAddress: secondaryToken?.contractAddress || null,
                fromContractAddress: mainToken?.contractAddress || null,
                toAddress: address
            })
        } else {
            sendTransaction({
                wallet: wallet?.accounts[0],
                amount: value,
                countFrom: mainTokenCount,
                countTo: dataForChange?.to?.amount,
                tokenFrom: mainToken?.contractAddress || null,
                tokenTo: secondaryToken?.contractAddress || null,
                address: address,
            })
        }
    }

    return (
        <div className="swaper-wrapper">
            <MobHeader/>
            <Wrapper>
                <div>
                    <SwaperNav/>
                    <div className="swaper">
                        <div className="swaper-cards">
                            <CoinCard
                                isEditable={true}
                                name={'Вы продаете'}
                                tokenCount={1}

                                setMainTokenCount={setMainTokenCount}
                                value={mainTokenCount}
                                token={mainToken?.symbol}
                                tokenName={mainToken?.symbol}
                                balance={userBalance?.find(item => item?.token === mainToken?.symbol)?.amount || 0}
                                count={tokens?.find(item => item?.fullName === mainToken?.fullName)?.price || 0}
                                onPress={
                                    () => isMob
                                        ? setModalOpenToken({
                                            isOpen: true,
                                            isMain: true,
                                        })
                                        : navigate('/main/pools/choose-token?isMain=true')
                                }
                            />
                            <div className="swaper-cards-arrow">
                                <BigArrowToBottom/>
                            </div>
                            <CoinCard
                                isEditable={false}
                                bcgColor={'#4b4a61'}
                                name={'Вы покупаете'}
                                tokenCount={dataForChange?.to?.amount || 0}

                                balance={userBalance?.find(item => item?.token === secondaryToken?.symbol)?.amount || 0}
                                token={secondaryToken?.symbol}
                                tokenName={secondaryToken?.symbol}
                                count={tokens?.find(item => item?.fullName === secondaryToken?.fullName)?.price || 0}
                                onPress={
                                    () => isMob
                                        ? setModalOpenToken({
                                            isOpen: true,
                                            isMain: false,
                                        })
                                        : navigate('/main/pools/choose-token?isMain=false')
                                }
                            />
                        </div>

                        <div className="swaper-equals">
                            <WarningIcon/>
                            {/*1 {mainToken?.symbol} = 714.543 {secondaryToken?.symbol} ($331.42)*/}

                            {
                                getCostPerOne(
                                    value,
                                    dataForChange?.to?.amount,
                                    tokens?.find(item => item?.fullName === secondaryToken?.fullName)?.price * dataForChange?.to?.amount,
                                    mainToken?.symbol,
                                    secondaryToken?.symbol
                                )
                            }
                        </div>

                        {!hasProvider
                            ?
                            <Button
                                startIcon={<WalletIcon/>}
                                text={'Установить metamask'}
                                height={56}
                                onClick={() => window.open('https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?hl=ru', '_blank')?.focus()}
                            />
                            : wallet?.accounts?.length > 0
                                ?
                                <>
                                    {wallet.chainId !== decimalChainId ? (
                                        <Button
                                            text={'Изменить канал'}
                                            height={56}
                                            onClick={changeNetwork}
                                        />
                                    ) : (
                                        <>
                                            {/*<Button*/}
                                            {/*    text={'Разрешить обмен'}*/}
                                            {/*    height={56}*/}
                                            {/*    onClick={() => isMob ? openModalConfirm() : navigate('/main/swap/confirm-swapper')}*/}
                                            {/*/>*/}
                                            <div style={{marginTop: 10}}/>
                                            <Button
                                                disabled={
                                                    changeLoader ||
                                                    value > userBalance?.find(item => item?.token === mainToken?.symbol)?.amount ||
                                                    dataForChange === 'notReal'
                                                }
                                                isLoading={changeLoader}
                                                text={'Swap'}
                                                height={56}
                                                onClick={onSwap}
                                            />
                                        </>
                                    )}
                                </>
                                :
                                <>
                                    <Button
                                        startIcon={<WalletIcon/>}
                                        text={'Сonnect walley'}
                                        height={56}
                                        onClick={handleConnect}
                                    />
                                </>

                        }

                        {(dataForChange === 'notReal' || value > userBalance?.find(item => item?.token === mainToken?.symbol)?.amount) &&
                        <>
                            <div style={{marginTop: 10}}/>
                            <WarningBlock
                                text={'Обратите внимание, что курс изменился. Вы не укладываетесь в рамки проскальзывания, проверьте курс'}
                            />
                        </>
                        }


                        {/*<div style={{marginTop: 10}}/>*/}
                        {/*<Button*/}
                        {/*    text={'Разрешить обмен'}*/}
                        {/*    height={56}*/}
                        {/*    onClick={() => isMob ? openModalConfirm() : navigate('/main/swap/confirm-swapper')}*/}
                        {/*/>*/}

                        {/*<div style={{marginTop: 10}}/>*/}
                        {/*<Button*/}
                        {/*    disabled={true}*/}
                        {/*    text={'Swap'}*/}
                        {/*    height={56}*/}
                        {/*    onClick={() => {*/}
                        {/*    }}*/}
                        {/*/>*/}

                        {/*<div style={{marginTop: 10}}/>*/}
                        {/*<Button*/}
                        {/*    disabled={true}*/}
                        {/*    text={'Недостаточно CRYG на балансе'}*/}
                        {/*    height={56}*/}
                        {/*    onClick={() => {*/}
                        {/*    }}*/}
                        {/*/>*/}


                    </div>
                </div>
            </Wrapper>

            {mainState === 'main' &&
            <>
                <div style={{marginTop: 20}}/>
                <ExtraInfo
                    isBurger={true}
                    keyText={'DEL цена покупки'}
                    valueText={'1.334 CRYG'}
                    extraValue={'~$331.62'}
                    valueColor={'#d9d7e8'}
                    extraValueColor={'#5f5e70'}
                />
                <ExtraInfo
                    isBurger={false}
                    changeText={'Изменить'}
                    keyText={'Цена газа'}
                    valueText={'Высокая'}
                    extraValue={'~$331.62'}
                    valueColor={'#5f5e70'}
                    extraValueColor={'#d9d7e8'}
                />
                <ExtraInfo
                    isBurger={false}
                    keyText={'Маршрут'}
                    valueText={'CRYG > DEL'}
                />
            </>
            }

            <ModalComfirmSwap isOpen={modalConfirm} onClose={closeModalConfirm}/>
            <ChooseTokenModal isOpen={modalOpenToken.isOpen} onClose={closeModal} isMain={modalOpenToken.isMain}/>
            <ModalLoaderSwapper/>
            <ModalSuccessSwap/>
        </div>

    );
};

export default Swap;