import React from 'react';
import {PieChart, Pie, Cell, ResponsiveContainer} from 'recharts';
import './styles.scss'
import CRYGIcon from '../../../assets/img/CRYGIcon.svg'
import {useSelector} from "react-redux";

const data = [
    {name: 'CRYG', value: 120},
    {name: 'CRYG', value: 12},
];

const COLORS = ['#222342', '#40A5F3']; // Colors for each data point

const CustomPieChart = () => {

    const {isMob} = useSelector(state => state.adaptive)


    return (
        <div>
            <div className="pie-text">
                Моя ликвидность
            </div>
            <div className="pie-wrapper">
                <ResponsiveContainer width="100%" height={190}>
                    <PieChart >
                        <Pie
                            data={data}
                            dataKey="value"
                            cx={90}
                            cy={90}
                            outerRadius={90}
                            fill="#8884d8"
                            label
                            stroke="none" // Set stroke to "none" or "transparent"
                        >
                            {data.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]}/>
                            ))}
                        </Pie>
                    </PieChart>
                </ResponsiveContainer>
                <div className="pie-wrapper-data">
                    <div className="pie-wrapper-data-item">
                        <img src={CRYGIcon} alt=""/>
                        <div className="pie-wrapper-data-item-text">
                            12,450 CRYG
                        </div>
                    </div>
                    <div className="pie-wrapper-data-item">
                        <img src={CRYGIcon} alt=""/>
                        <div className="pie-wrapper-data-item-text">
                            12,450 CRYG
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default CustomPieChart;