import {useState} from 'react';
import {useDispatch} from 'react-redux';
import './styles.scss';

const Input = ({
                   bcgColor = '#3F4157',
                   borderColor = '#3F4157',
                   isWithBoxShadow = false,
                   value,
                   onChange,
                   leftIcon,
                   error,
                   ...props
               }) => {

    const [isFocus, setIsFocus] = useState(false);
    const dispatch = useDispatch();

    return (
        <div className="input">
            <div
                style={{
                    backgroundColor: bcgColor,
                    borderColor: borderColor,
                }}
                className={`
                input__wrap-login
                ${isFocus ? ' input__wrap_focus' : ''}
                ${error ? ' input__wrap_error' : ''}
                ${isWithBoxShadow && 'box-shadow'}
                `}
            >
                {leftIcon && (
                    <div className="input__left">
                        {leftIcon}
                    </div>
                )}
                <input
                    className="input__input"
                    type="text"
                    value={value}
                    onChange={onChange}
                    autoComplete="off"
                    onFocus={() => {
                        setIsFocus(true);
                    }}
                    onBlur={() => {
                        setIsFocus(false);
                    }}
                    {...props} />
            </div>
            {(error && typeof error == 'string') && (
                <div className="input__error">
                    <p className="input__error-text">{error}</p>
                </div>
            )}
        </div>
    );
};

export default Input;
