import React from "react";

function LanguageIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            fill="none"
            viewBox="0 0 18 18"
        >
            <path
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.125"
                d="M1.5 9a7.5 7.5 0 1015 0 7.5 7.5 0 00-15 0z"
            />
            <path
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.125"
                d="M9.75 1.537S12 4.5 12 9s-2.25 7.462-2.25 7.462m-1.5 0S6 13.5 6 9s2.25-7.463 2.25-7.463M1.973 11.625h14.055M1.973 6.375h14.054"
            />
        </svg>
    );
}

export default LanguageIcon;
