import React from "react";

function ArrowToBottom() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="15"
            height="15"
            fill="none"
            viewBox="0 0 15 15"
        >
            <path
                fill="#fff"
                d="M13.325 5.733l-4.322 4.322a2.042 2.042 0 01-2.888 0L1.792 5.733 3.525 4 7.56 8.037l4.033-4.033 1.733 1.729z"
            />
        </svg>
    );
}

export default ArrowToBottom;
