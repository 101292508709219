import React, {useState} from 'react';
import './style.scss';
import {Link, useNavigate} from "react-router-dom";

const Footer = () => {

    return (
        <div className="footer">
            <div className="footer-left-block">
                Copyright. @ 2023 Corp Name Все права защищены
            </div>
            <div className="footer-right-block">
                <Link to={'/system/user-agreements'}>
                    Пользовательское соглашение
                </Link>
                <div className="vertical-line"/>
                <Link to={'/system/privacy-policy'}>
                    Политика конфиденциальности
                </Link>
            </div>
        </div>
    );
};

export default Footer;