import React, {useState} from 'react';
import './style.scss'
import CRYG from '../../../assets/img/CRYGIconCycle.svg'
import DEL from '../../../assets/img/DELIconCycle.svg'

const PoolsItem = ({item, onClick}) => {

    return (
        <div className="pools-item" onClick={onClick}>
            <div className="pools-item-from-to">
                <div className="pools-item-from-to-imgs">
                    <img src={DEL} alt="" className="pools-item-from-to-imgs-img-first"/>
                    <img src={CRYG} alt="" className="pools-item-from-to-imgs-img-second"/>
                </div>
                <div className="pools-item-from-to-text">
                    {`${item.from}/${item.to}`}
                </div>
            </div>
            <div className="pools-item-change-count">
                {`${item.fromCount}/${item.toCount}`}
            </div>
            <div style={{marginTop: 6}}/>
            <div className="pools-item-statistic-items">
                <div className="pools-item-statistic-items-item">
                    <p> Доходность годовая:</p>
                    <span>{item.annualProfitability}%</span>
                </div>
                <div className="pools-item-statistic-items-item">
                    <p> Доходность за 24 ч:</p>
                    <span>{item.profitabilityPerDay}%</span>
                </div>
                <div className="pools-item-statistic-items-item">
                    <p> Объем торгов за 24 ч:</p>
                    <span>{item.tradingVolume}</span>
                </div>
            </div>

        </div>
    );
};

export default PoolsItem;