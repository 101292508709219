import { createSlice } from '@reduxjs/toolkit';
import { configureTransport } from '../../api/transport';

export const counterSlice = createSlice({
	name: 'auth',
	initialState: {
		wallet: {
			accounts: [],
			balance: "",
			chainId: ""
		}
	},
	reducers: {
		setWallet: (state, action) => {
			state.wallet = action.payload;
		},
	},
});

export const {setWallet} = counterSlice.actions;

export default counterSlice.reducer;
