import React from 'react';
import './style.scss'
import Button from "../../components/Common/Button";
import landingItroHand from '../../assets/img/landingItroHand.png'
import landingItroHandMob from '../../assets/img/landingItroHandMob.png'
import landingPriceBck from '../../assets/img/landingPricebck.png'
import landingPricebckMob from '../../assets/img/landingPricebckMob.png'
import landingFasionBck from '../../assets/img/landingFasionBck.png'
import landingFinancebck from '../../assets/img/landingFinancebck.png'
import landingIntroBck2 from '../../assets/img/landingIntroBck2.png'
import mainLogo from "../../assets/img/mainLogo.png";
import { useSpring, animated } from 'react-spring';
import NumberAnimation from "../../components/Common/NumberAnimation";

const Landing = () => {

    return (
        <div className="landing">
            <img src={landingIntroBck2} alt="" className='landing-intro-back'/>
            <div className="landing__logo">
                <img src={mainLogo} alt=""/>
                <h1>Canyon Swap</h1>
            </div>
            <div className="landing-intro">
                <div className="landing-intro-left">
                    <div className="landing-intro-left-title">
                        Live and on-demand trading
                    </div>
                    <div className="landing-intro-left-desc">
                        Lorem ipsum dolor sit amet, consectetur
                        adipiscing elit. In sit amet commodo orci,
                        non tempus metus.
                    </div>
                    <div className="landing-intro-left-button">
                        <Button
                            // onClick={onSave}
                            // isLoading={isLoading}
                            text={'Explore Now'}
                            height={'100%'}
                        />
                    </div>
                </div>
                <div className="landing-intro-right">
                    <img
                        className="landing-intro-right-img"
                        src={landingItroHand}
                        alt=""/>
                    <img
                        className="landing-intro-right-img-mob"
                        src={landingItroHandMob}
                        alt=""/>
                </div>
            </div>

            <div className="landing-price">
                <div className="landing-price__item">
                    <div className="landing-price__item-count">
                        <NumberAnimation targetValue={370} />
                    </div>
                    <div className="landing-price__item-desc">
                        Liquidity sources
                    </div>
                </div>
                <div className="landing-price__item">
                    <div className="landing-price__item-count">
                        $<NumberAnimation targetValue={282} />B+
                    </div>
                    <div className="landing-price__item-desc">
                        Total volume
                    </div>
                </div>
                <div className="landing-price__item">
                    <div className="landing-price__item-count">
                        <NumberAnimation targetValue={29.2} />M+
                    </div>
                    <div className="landing-price__item-desc">
                        Total trades
                    </div>
                </div>
                <div className="landing-price__item">
                    <div className="landing-price__item-count">
                        <NumberAnimation targetValue={4.5} />M+
                    </div>
                    <div className="landing-price__item-desc">
                        Total wallets
                    </div>
                </div>
            </div>

            <div className="landing-info">
                <div className="landing-info-left">
                    <div className="landing-info-left-title">
                        Never-ending
                        liquidity
                    </div>
                    <div className="landing-info-left-desc">
                        1inch instantly analyzes thousands of quotes and fees
                        across multiple DEXes to provide users with the best rates.

                    </div>
                    <div className="landing-info-left-button">
                        <Button
                            backColor={'#1b1c33'}
                            // onClick={onSave}
                            // isLoading={isLoading}
                            text={'Explore Now'}
                            height={56}
                        />
                    </div>
                </div>
                <div className="landing-info-right">
                    <img
                        src={landingPriceBck}
                        alt=""
                        className='landing-info-right-img'
                    />
                    <img
                        src={landingPricebckMob}
                        alt=""
                        className='landing-info-right-img-mob'
                    />
                </div>
            </div>

            <div className="landing-fusion">
                <div className="landing-fusion-content">
                    <div className="landing-fusion-content-title">
                        1inch Fusion
                    </div>
                    <div className="landing-fusion-content-text">
                        1inch instantly analyzes thousands of quotes and fees across multiple DEXes to provide users with the best rates.
                    </div>
                    <div className="landing-fusion-text">
                        1inch instantly analyzes thousands of quotes and fees across multiple DEXes to provide users with the best rates.
                    </div>
                </div>


                <img
                    className="landing-fusion-img"
                    src={landingFasionBck}
                    alt=""
                />
            </div>

            <div className="landing-finance">
                <div className="landing-finance-left">
                    <div className="landing-finance-left-title">
                        Your finance
                        shield
                    </div>
                    <div className="landing-finance-left-desc">
                        1inch instantly analyzes thousands of quotes and fees across multiple DEXes to provide users with the best rates.
                    </div>
                </div>
                <div className="landing-finance-right">
                    <img
                        className="landing-finance-right-img"
                        src={landingFinancebck}
                        alt=""
                    />
                </div>
            </div>
        </div>
    );
};

export default Landing;