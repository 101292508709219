import React from "react";

function PersonIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="none"
            viewBox="0 0 20 20"
        >
            <path
                fill="#fff"
                d="M6.25 5.417A3.755 3.755 0 0010 9.167a3.755 3.755 0 003.75-3.75A3.755 3.755 0 0010 1.667a3.755 3.755 0 00-3.75 3.75zM16.667 17.5h.833v-.833a5.84 5.84 0 00-5.833-5.833H8.333A5.84 5.84 0 002.5 16.667v.833h14.167z"
            />
        </svg>
    );
}

export default PersonIcon;
