import React, {useEffect, useState} from "react";
import './styles.scss'
import BackButton from "../../Common/BackButton";
import ShortCoinCard from "../ShortCoinCard";
import Button from "../../Common/Button";
import ChooseTokenModal from "../ChooseTokenModal";
import {useConfirm} from "../../../controllers/ModalConfirm";
import CircleArrowToBottom from "../../../assets/icons/CircleArrowToBottom";
import CoinCard from "../CoinCard";
import BigArrowToBottom from "../../../assets/icons/BigArrowToBottom";
import ExtraInfo from "../ExtraInfo";
import CheckBox from "../../Common/CheckBox";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";

const ModalComfirmSwap = ({isOpen, onClose}) => {

    const confirmU = useConfirm();

    const [checkBox, setCheckBox] = useState(false)
    const navigate = useNavigate();
    const {isMob} = useSelector(state => state.adaptive)
    const [modalOpenToken, setModalOpenToken] = useState(false);

    const onAddLiquidity = () => {

        confirmU({
            isVisible: true,
            title: 'Транзакция отправлена',
            img: <CircleArrowToBottom/>,
            text: 'На ваш кошелек будет зачислено',
            textExtra: '100 CRYG',
            agreeText: 'Подтвердить',
            dontAgreeText: 'Отменить',
            onPress: () => onClose
        })
    }

    const openModal = () => {
        setModalOpenToken(true);
    };

    const closeModal = () => {
        setModalOpenToken(false);
    };

    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    }, [isOpen]);

    return (
        <>
            {isOpen && <div className="modal-overlay" onClick={onClose}/>}
            <div className={`modal-confirm ${isOpen ? 'open-confirm' : ''}`}>
                <div className="modal-content-cinfirm-swap">

                    <div className="swaper-confirm ">
                        <BackButton
                            onPress={onClose}
                            title={'Подтвердить обмен'}
                        />

                        <div style={{marginTop: 24}}/>

                        <div className="swaper-cards">
                            <CoinCard
                                name={'Вы покупаете'}
                                balance={100}
                                isWithhMax={true}
                                token={'1'}
                                tokenCount={1}
                                tokenName={'CRYG'}
                                count={'~$333.73'}
                                onPress={() => isMob ? openModal() : navigate('/main/pools/add-liquidity')}
                            />
                            <div className="swaper-cards-arrow">
                                <BigArrowToBottom/>
                            </div>
                            <CoinCard
                                bcgColor={'#4b4a61'}
                                name={'Вы покупаете'}
                                balance={0}
                                isWithhMax={false}
                                token={'0'}
                                tokenCount={714.543016}
                                tokenName={'DELL'}
                                count={'~$333.73'}
                                onPress={() => isMob ? openModal() : navigate('/main/pools/add-liquidity')}
                            />
                        </div>
                        <div style={{marginTop: 20}}/>

                        <ExtraInfo
                            keyText={'1 CRYG цена'}
                            valueText={'~$331.62'}
                            valueColor={'#d9d7e8'}
                            extraValue={'0.076452793 DEL'}
                        />
                        <ExtraInfo
                            keyText={'1 DEL цена'}
                            valueText={'~$331.62'}
                            valueColor={'#d9d7e8'}
                            extraValue={'0.076452793 USDC'}
                        />
                        <ExtraInfo
                            keyText={'Минимально допустимо'}
                            valueColor={'#d9d7e8'}
                            valueText={'0.076452793 CRYG'}
                        />
                        <ExtraInfo
                            changeText={"Изменить"}
                            valueColor={'#d9d7e8'}
                            keyText={'Макс.проскальзывание'}
                            valueText={'1%'}
                        />
                        <ExtraInfo
                            changeText={"Изменить"}
                            keyText={'Цена газа'}
                            valueText={'Высокая'}
                            valueColor={'#5f5e70'}
                            extraValueColor={'#d9d7e8'}
                            extraValue={'~$331.62'}
                        />
                        <div style={{marginTop: 20}}/>

                        <CheckBox
                            value={checkBox}
                            onChange={() => setCheckBox(!checkBox)}
                            text={'Получить DEL на другой кошелек'}
                        />
                        <div style={{marginTop: 20}}/>

                        <Button
                            text={'Подтвердить обмен'}
                            height={56}
                            onClick={() => {
                            }}
                        />


                    </div>
                </div>
            </div>
            <ChooseTokenModal isOpen={modalOpenToken} onClose={closeModal}/>
        </>

    );
};

export default ModalComfirmSwap