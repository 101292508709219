import { useSelector, useDispatch } from 'react-redux';
import {setTokens, logout, setUser} from '../redux/auth';
import { getTransport, configureTransport } from "./transport";
import TokenStorage from "./TokenStorage";
import {useNavigate} from "react-router-dom";

const generateHook = (callback) => () => {

	const dispatch = useDispatch();
	// const refreshToken = useSelector((state) => state.auth.refreshToken);
	const navigate = useNavigate();

	return callback(async (e) => {
		if (e.response.status !== 401) throw e;
		try {
			// const res = await get('refresh', { headers: { Authorization: `Bearer ${refreshToken || TokenStorage.getRefreshToken()}`}});
			// await configureTransport(res.data.tokens.accessToken);
			// await dispatch(setTokens({
			// 	accessToken: res.data.tokens.accessToken,
			// 	refreshToken: res.data.tokens.refreshToken,
			// }));
			// await dispatch(setUser(res.data.user));

			// await TokenStorage.setAccessToken(res.data.tokens.accessToken)
			// await TokenStorage.setRefreshToken(res.data.tokens.refreshToken)
			// await TokenStorage.setUser(res.data.user)

			// const buf = (await getTransport()({
			// 	...e.response.config,
			// 	headers: { 'Authorization': 'Bearer ' + res.data.tokens.accessToken }
			// }))?.data;
			// return buf;
		}
		catch (err) {
			// dispatch(logout());
			TokenStorage.logOut();
			navigate('/login')
			// throw e;
		}
	});
};

export const usePost = generateHook((middleware) => (
	(path, payload, config) => getTransport()
	.post(`/api/${path}`, payload, config)
	.then((response) => response.data)
	.catch(middleware)
	));

export const useGet = generateHook((middleware) => (
	(path) => getTransport()
	.get(`/api/${path}`)
	.then((response) => response.data)
	.catch(middleware)
	));

export const usePut = generateHook((middleware) => (
	(path, payload, config) => getTransport()
	.put(`/api/${path}`, payload, config)
	.then((response) => response.data)
	.catch(middleware)
	));

export const usePatch = generateHook((middleware) => (
	(path, payload, config) => getTransport()
	.patch(`/api/${path}`, payload, config)
	.then((response) => response.data)
	.catch(middleware)
	));

export const useDelete = generateHook((middleware) => (
	(path, config) => getTransport()
	.delete(`/api/${path}`, config)
	.then((response) => response.data)
	.catch(middleware)
	));

export const get = (path, config) => getTransport()
	.get(`/api/${path}`, config).then((response) => response.data);

export const post = async (path, payload, config) => getTransport()
	.post(`/api/${path}`, payload, config)
	.then((response) => response.data);

export const put = (path, payload = {}) => getTransport()
	.put(`/api/${path}`, payload)
	.then((response) => response.data);

export const patch = (path, payload = {}) => getTransport()
	.patch(`/api/${path}`, payload)
	.then((response) => response.data);

export const httpDelete = (path, config) => getTransport()
	.delete(`/api/${path}`, config)
	.then((response) => response.data);
