import React, {useEffect, useRef, useState} from 'react';
import './style.scss'

const DropDown = ({
                      startIcon,
                      backIcon,
                      language,
                      setLanguage,
                      languages,
                  }) => {

    const dropDownListRef = useRef(null);
    const [isShow, setIsShow] = useState(false)

    const onChange = (item) =>{
        setIsShow(false)
        setLanguage(item)
    }

    useEffect(() => {
        if (isShow) {
            const handleClickOutside = (event) => {
                if (dropDownListRef.current && !dropDownListRef.current.contains(event.target)) {
                    setIsShow(false);
                }
            };

            document.addEventListener('mousedown', handleClickOutside);
            return () => {
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }
    }, [isShow, dropDownListRef]);

    return (
        <div className="drop-down-button__wrap">
            <button className="drop-down-button__wrap-main" onClick={() => setIsShow(!isShow)}>
                {!!startIcon && startIcon}
                <h5>
                    {language}
                </h5>
                {!!backIcon && backIcon}
            </button>
            {isShow &&
                <div
                    className="drop-down-button__wrap-drop-list"
                    ref={dropDownListRef}
                >
                    {languages.map((item) =>
                        <li onClick={() =>onChange(item)}>
                            {item}
                        </li>
                    )}
                </div>
            }
        </div>
    );
};

export default DropDown;