import React from "react";

function BigArrowToBottom() {
    return (
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12.3799 21.7956C12.5751 21.9905 12.8914 21.9905 13.0866 21.7956L22.1923 12.7022C22.3879 12.5069 22.3879 12.19 22.1923 11.9946L21.1826 10.9863C20.9874 10.7913 20.6712 10.7913 20.4759 10.9863L14.8132 16.6413C14.4982 16.9559 13.9599 16.7328 13.9599 16.2875L13.9599 3.04844C13.9599 2.77229 13.7361 2.54844 13.4599 2.54844H12.0066C11.7304 2.54844 11.5066 2.77229 11.5066 3.04844L11.5066 16.2875C11.5066 16.7328 10.9684 16.9559 10.6533 16.6413L4.99057 10.9863C4.79536 10.7913 4.47915 10.7913 4.28394 10.9863L3.2742 11.9946C3.07861 12.19 3.07861 12.5069 3.2742 12.7022L12.3799 21.7956Z"
                fill="white"/>
        </svg>
    );
}

export default BigArrowToBottom;
