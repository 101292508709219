import React from 'react';
import './style.scss'
import {Link} from "react-router-dom";
import ArrowToLeft from "../../../assets/icons/ArrowToLeft";

const UserAgreement = () => {
    return (
        <div className="user-agreement">
            <div className="privacy-policy-back-button">
                <Link to={'/system'}>
                    <ArrowToLeft/>
                </Link>
                <h4>Назад</h4>
                <div/>
            </div>
            <h1>
                Пользовательские соглашения
            </h1>
            <p>
                By the entry of your personal user name and assigned password you have
                been brought to this account verification and terms of use agreement page.
                To use TyMetrix 360 , please acknowledge your agreement to the terms of
                use noted below. We are pleased to have you as a new member of the most
                advanced network of legal professionals in the world. Please read the terms
                of use and click the appropriate button below to begin using the collaborative
                network(s) covered by your license.
            </p>
            <p>
                By the entry of your personal user name and assigned password you have
                been brought to this account verification and terms of use agreement page.
                To use TyMetrix 360 , please acknowledge your agreement to the terms of
                use noted below. We are pleased to have you as a new member of the most
                advanced network of legal professionals in the world. Please read the terms
                of use and click the appropriate button below to begin using the collaborative
                network(s) covered by your license.
            </p>
            <p>
                By the entry of your personal user name and assigned password you have
                been brought to this account verification and terms of use agreement page.
                To use TyMetrix 360 , please acknowledge your agreement to the terms of
                use noted below. We are pleased to have you as a new member of the most
                advanced network of legal professionals in the world. Please read the terms
                of use and click the appropriate button below to begin using the collaborative
                network(s) covered by your license.
            </p>
        </div>
    );
};

export default UserAgreement;