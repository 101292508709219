import React from "react";

function FAQIcon({color = '#222342'}) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="none"
            viewBox="0 0 32 32"
        >
            <path
                fill={color}
                d="M21.333 26.667v2.667h-16a2.675 2.675 0 01-2.666-2.667V9.334h2.666v17.333h16zm5.334-24h-16A2.675 2.675 0 008 5.334v16C8 22.8 9.2 24 10.667 24h16c1.466 0 2.666-1.2 2.666-2.666v-16c0-1.467-1.2-2.667-2.666-2.667zm-6.667 16h-2.667V16H20v2.667zm2.4-6.933c-.4.533-.933.933-1.467 1.2-.266.266-.533.4-.666.666-.267.267-.267.667-.267 1.067h-2.667c0-.667.134-1.2.4-1.6.267-.4.667-.8 1.334-1.2.4-.267.666-.533.8-.8.266-.267.266-.667.266-1.067 0-.4-.133-.8-.4-1.066-.266-.267-.533-.4-1.066-.4-.4 0-.667.133-.934.266-.266.267-.4.534-.4.934H14.8c0-1.067.267-1.867 1.067-2.4.933-.4 1.866-.667 3.066-.667 1.2 0 2.267.267 2.934.933.666.667 1.066 1.467 1.066 2.4 0 .667-.266 1.2-.533 1.734z"
            />
        </svg>
    );
}

export default FAQIcon;
