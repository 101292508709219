import React, {useEffect} from "react";
import {DndProvider} from "react-dnd";
import {Provider, useSelector} from 'react-redux';
import {PersistGate} from 'redux-persist/integration/react';
import {HTML5Backend} from "react-dnd-html5-backend";
import {useRoutes, useNavigate, useLocation} from "react-router-dom";
import store, {persistor} from './store';
import navigation from "./navigation";
import AuthController from './controllers/AuthController';
import TokenStorage from "./api/TokenStorage";
import {ThemeProvider, StyledEngineProvider} from '@material-ui/core';
import theme from "./theme";
import {Loader} from "./components/Common/Loader";
import {AlertProvider} from "./components/Common/AlertNotification";
import {ConfirmProvider} from "./controllers/ModalConfirm";
import AdapriveController from "./controllers/AdapriveController";
import SwapperController from "./controllers/SwapperController";
import {SocketProvider} from "./controllers/SocketProvider";

const App = () => {

    const content = useRoutes(navigation);
    const navigate = useNavigate();

    // if (window.location.pathname.includes('app') && !TokenStorage.getAccessToken()) {
    //     navigate('/login', {replace: true});
    // }

    return (
        <Provider store={store}>
            <ThemeProvider theme={theme}>
                <PersistGate loading={null} persistor={persistor}>
                    <DndProvider backend={HTML5Backend}>
                        <SocketProvider>
                            <AuthController>
                                <AlertProvider>
                                    <ConfirmProvider>
                                        <AdapriveController>
                                            <SwapperController>
                                                {content}
                                            </SwapperController>
                                        </AdapriveController>
                                    </ConfirmProvider>
                                </AlertProvider>
                            </AuthController>
                        </SocketProvider>
                    </DndProvider>
                </PersistGate>
            </ThemeProvider>
        </Provider>
    );
}

export default App;
