import React, {useContext, useEffect, useRef, useState} from 'react';
import {
    setAddress,
    setChangeLoader,
    setDataForChange,
    setIsGetBalanceUser,
    setSuccessModal,
    setTokens
} from "../../redux/swapper";
import {setWallet} from "../../redux/auth";
import detectEthereumProvider from "@metamask/detect-provider";
import {IO} from "../../index";
import {useDispatch, useSelector} from "react-redux";
import MetaMaskSDK from "@metamask/sdk";
import Web3 from "web3";
import ModalError from "../../components/Main/ModalError";

export const decimalChainId = "0x1344224";

export const decimalChainNetworkParams = {
    chainId: "0x1344224",
    chainName: "Decimal Devnet",
    rpcUrls: ["https://devnet-val.decimalchain.com/web3/"],
    nativeCurrency: {
        name: "DEL",
        symbol: "DEL",
        decimals: 18,
    },
    blockExplorerUrls: ["https://devnet.explorer.decimalchain.com"],
};

const MMSDK = new MetaMaskSDK({
    injectProvider: false,
    communicationLayerPreference: "webrtc",
});

const ethereum = MMSDK.getProvider();

export const web3 = new Web3(ethereum);

export const formatBalanced = (rawBalance) => {
    const balance = (parseInt(rawBalance) / 1e18).toFixed(2);
    return balance;
};

export const formatChainAsNum = (chainIdHex) => {
    const chainIdNum = parseInt(chainIdHex);
    return chainIdNum;
};

const SocketContext = React.createContext();

export const useSocket = () => {
    const {
        changeNetwork,
        updateWallet,
        handleConnect,
        getTokenUpdate,
        tokenSwap,
        tokenCanSwap,
        hasProvider
    } = useContext(SocketContext);

    return {
        changeNetwork,
        updateWallet,
        handleConnect,
        getTokenUpdate,
        tokenSwap,
        tokenCanSwap,
        hasProvider
    };
};

export const SocketProvider  = ({children}) => {

    const {current: socket} = useRef(IO);
    const dispatch = useDispatch()

    const {wallet} = useSelector(state => state.auth)

    const [hasProvider, setHasProvider] = useState(null);
    const initialState = {accounts: [], balance: "", chainId: ""};

    const [isConnecting, setIsConnecting] = useState(false);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [errorModal, setErrorModal] = useState({
        isOpen: false,
        text: null,
    })

    useEffect(() => {

        socket.emit("tokens:get");

        socket.on("tokens:update", (data) => {
            console.log(data)
            dispatch(setTokens(data?.tokens))
            dispatch(setAddress(data?.address))
        })

        socket.on("swap:check", (data) => {
            // console.log(data)
            if (data){
                dispatch(setDataForChange(data))
            } else {
                dispatch(setDataForChange('notReal'))
            }
        })

        socket.on("swap:success", (data) => {
            console.log(data)
            dispatch(setIsGetBalanceUser(true))
            dispatch(setSuccessModal(data))
            dispatch(setChangeLoader(false))
        })

        socket.on("exception", (data) => {
            console.log(data)
            if (data.action === 'swap:get'){
                setErrorModal({
                    text: "Ошибка токена, перепроверьте, что ваш токен не заблокировал и работает",
                    isOpen: true
                })
            }
            if (data.action === 'swap'){
                setErrorModal({
                    text: "Ошибка отправки транзакции",
                    isOpen: true
                })
            }
            if (data.action === 'tokens:get'){
                setErrorModal({
                    text: "Ошибка при получении токенов",
                    isOpen: true
                })
            }
            if (data.action === 'swap:get'){
                setErrorModal({
                    text: "Ошибка при попытке получить стоимость токенов",
                    isOpen: true
                })
            }
        })

        const refreshAccounts = (accounts) => {

            if (accounts.length > 0) {
                updateWallet(accounts);
            } else {
                // if length 0, user is disconnected
                dispatch(setWallet(initialState));
            }
        };

        const refreshChain = (chainId) => {
            dispatch(setWallet({...wallet, chainId}));
        };

        const getProvider = async () => {
            const provider = await detectEthereumProvider({silent: true});
            setHasProvider(Boolean(provider));

            if (provider) {
                const accounts = await window?.ethereum?.request({
                    method: "eth_accounts",
                });
                refreshAccounts(accounts);
                window.ethereum.on("accountsChanged", refreshAccounts);
                window.ethereum.on("chainChanged", refreshChain);
            }
        };

        getProvider();

        return () => {
            window.ethereum?.removeListener("accountsChanged", refreshAccounts);
            window.ethereum?.removeListener("chainChanged", refreshChain);
        };
    }, []);

    const changeNetwork = async () => {
        try {
            await window.ethereum.request({
                method: "wallet_switchEthereumChain",
                params: [{chainId: decimalChainId}],
            });
            await handleConnect()
        } catch (err) {
            // This error code indicates that the chain has not been added to MetaMask
            if (err.code === 4902) {
                await window.ethereum.request({
                    method: "wallet_addEthereumChain",
                    params: [decimalChainNetworkParams],
                });
            }
        }
    };

    const updateWallet = async (accounts) => {
        const balance = formatBalanced(
            await window.ethereum.request({
                method: "eth_getBalance",
                params: [accounts[0], "latest"],
            })
        );
        const chainId = await window?.ethereum?.request({
            method: "eth_chainId",
        });
        dispatch(setWallet({accounts, balance, chainId}));

    };

    const handleConnect = async () => {
        setIsConnecting(true);
        await ethereum
            .request({
                method: "eth_requestAccounts",
            })
            .then((accounts) => {
                setError(false);
                updateWallet(accounts);
            })
            .catch((err) => {
                setError(true);
                setErrorMessage(err.message);
            });
        setIsConnecting(false);
    };


    const getTokenUpdate = () =>{
        socket.emit("tokens:get");
    }

    const tokenSwap = ({fromAddress, fromAmount, toAddress}) => {
        socket.emit("swap:get",
            {
                from: {
                    address: fromAddress,
                    amount: fromAmount
                },
                to: {
                    address: toAddress
                }
            }
        )
    }

    const tokenCanSwap = (sendHash) => {
        console.log('here')
        console.log(sendHash)
        socket.emit("swap", 1)
    }

    return(
        <SocketContext.Provider
            value={{
                changeNetwork,
                updateWallet,
                handleConnect,
                getTokenUpdate,
                tokenSwap,
                tokenCanSwap,
                hasProvider
            }}>
            {children}

            <ModalError
                isVisible={errorModal.isOpen}
                text={errorModal.text}
                onClose={() => setErrorModal({isOpen: false, text: null})}
            />
        </SocketContext.Provider>
    )
};

export default SocketProvider;