import React from "react";

function SystemIcon({color = '#222342'}) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="none"
            viewBox="0 0 32 32"
        >
            <path
                fill={color}
                fillRule="evenodd"
                d="M19 3.333A.333.333 0 0018.667 3H9.333a3.667 3.667 0 00-3.666 3.667v18.666A3.667 3.667 0 009.333 29h13.334a3.667 3.667 0 003.666-3.667V12.196a.333.333 0 00-.333-.333h-6a1 1 0 01-1-1v-7.53zm1 13a1 1 0 010 2h-8a1 1 0 110-2h8zm0 5.334a1 1 0 110 2h-8a1 1 0 010-2h8z"
                clipRule="evenodd"
            />
            <path
                fill="#222342"
                d="M21 3.765c0-.245.257-.401.448-.248.161.13.307.283.43.456l4.018 5.596c.09.128-.008.293-.165.293h-4.398A.333.333 0 0121 9.53V3.765z"
            />
        </svg>
    );
}

export default SystemIcon;
