import React, {useState} from 'react';
import './style.scss'
import Input from "../../components/Common/Input";
import PersonIcon from "../../assets/icons/Person";
import EmailIcon from "../../assets/icons/EmailIcon";
import Textarea from "../../components/Common/Textarea";
import QuestionIcon from "../../assets/icons/QuestionIcon";
import Button from "../../components/Common/Button";

const FeedBack = () => {

    const [values, setValues] = useState({
        name: '',
        email: '',
        question: '',
    })

    const [errors, setErrors] = useState({
        name: '',
        email: '',
        question: '',
    })

    const [isLoading, setIsLoading] = useState(false)

    const handleChangeValues = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
        setErrors({
            ...errors,
            [event.target.name]: ''
        });
    };


    const validation = () => {
        let isValid = true;
        let formErrors = {...errors};

        if (values.name === '') {
            formErrors.name = 'Поле должно быть запосленно';
            isValid = false;
        }

        if (values.email === '') {
            formErrors.email = 'Поле должно быть запосленно';
            isValid = false;
        }

        if (values.question === '') {
            formErrors.question = 'Поле должно быть запосленно';
            isValid = false;
        }

        setErrors(formErrors);
        return isValid
    }


    const onSave = () => {
        if (!validation()) return;

        setIsLoading(true)

        const data = {
            message: values.message,
            link: values.link,
            platform: values.platform,
        }

        setTimeout(() =>{
            setIsLoading(false)
        }, 1000)

    }

    return (
        <div className="feedback">
            <div className="feedback-container">
                <h2>
                    Остались вопросы?
                </h2>
                <h4>
                    Мы ответим на них
                </h4>
                <div className="feedback-container__inputs">
                    <Input
                        placeholder='Введите имя'
                        name='name'
                        value={values.name}
                        onChange={handleChangeValues}
                        leftIcon={<PersonIcon/>}
                        error={errors.name}
                    />
                    <Input
                        placeholder='Введите email'
                        name='email'
                        value={values.email}
                        onChange={handleChangeValues}
                        leftIcon={<EmailIcon/>}
                        error={errors.email}
                    />
                    <Textarea
                        name="question"
                        value={values.question}
                        leftIcon={<QuestionIcon/>}
                        onChange={handleChangeValues}
                        placeholder="Введите ваш вопрос"
                        error={errors.question}
                    />
                    <Button
                        onClick={onSave}
                        isLoading={isLoading}
                        text={'Отправить'}
                        height={56}
                    />
                </div>


            </div>
        </div>
    );
};

export default FeedBack;