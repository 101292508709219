import React from 'react';
import './style.scss'
import ArrowToTop from "../../../assets/icons/ArrowToTop";
import ArrowToBottom from "../../../assets/icons/ArrowToBottom";

const FAQItem = ({title, text, isOpen, onPress}) => {
    return (
        <button className="faq-item" onClick={onPress}>
            <div className="faq-item-titleBlock">
                <h2>
                    {title}
                </h2>
                {isOpen
                    ? <ArrowToTop/>
                    : <ArrowToBottom/>
                }
            </div>
            {isOpen &&
            <div className="faq-item-textBlock">
                <p>
                    {text}
                </p>
            </div>
            }
        </button>
    );
};

export default FAQItem;