import {useState} from 'react';
import {useDispatch} from 'react-redux';
import './styles.scss';

const Textarea = ({
                      value,
                      onChange,
                      error,
                      title,
                      leftIcon,
                      ...props
                  }) => {
    const [isFocus, setIsFocus] = useState(false);

    return (
        <div className={`textarea`}>
            <div
                className={`textarea__wrap${isFocus ? ' textarea__wrap_focus' : ''}${error ? ' textarea__wrap_error' : ''}`}>
                {leftIcon && (
                    <div className="textarea__left">
                        {leftIcon}
                    </div>
                )}
                <textarea
                    rows="4"
                    cols="50"
                    className="textarea__input"
                    type="text"
                    value={value}
                    onChange={onChange}
                    onFocus={() => {
                        setIsFocus(true);
                    }}
                    onBlur={() => {
                        setIsFocus(false);
                    }}
                    {...props} />
            </div>
            {error && (
                <div className="textarea__error">
                    <p className="textarea__error-text">{error}</p>
                </div>
            )}
        </div>
    );
};

export default Textarea;
