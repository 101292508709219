import React, {useContext, useEffect, useState} from 'react';
import "./styles.scss"
import Button from "../../components/Common/Button";

const Context = React.createContext();

const useConfirm = () => {
    const {setConfirmItem} = useContext(Context);

    return ({
                isVisible = false,
                title = '',
                img = '',
                text = '',
                textExtra = '',
                agreeText = '',
                dontAgreeText = '',
                onPress,
            }) => {
        setConfirmItem({
            isVisible,
            title,
            img,
            text,
            textExtra,
            agreeText,
            dontAgreeText,
            onPress
        })
    };
}

const ConfirmProvider = ({children}) => {
    const [confirmItem, setConfirmItem] = useState({
        isVisible: false,
        title: '',
        img: '',
        text: '',
        textExtra: '',
        agreeText: '',
        dontAgreeText: '',
        onPress: () => {
        }
    });

    const onAgree = () => {
        confirmItem?.onPress()
        onClose()
    }

    const onClose = () => {
        setConfirmItem({
            isVisible: false,
            title: '',
            img: '',
            text: '',
            textExtra: '',
            agreeText: '',
            dontAgreeText: '',
            onPress: () => {
            }
        })
    }

    return (
        <Context.Provider value={{setConfirmItem}}>
            {confirmItem.isVisible &&
            <div className="confirm-modal">
                <div className="confirm-modal-content">

                    <div className="confirm-modal-content-title">
                        {confirmItem?.title}
                    </div>

                    {confirmItem?.img &&
                    <div className="confirm-modal-content-img">
                        {confirmItem?.img}
                    </div>
                    }

                    <div className="confirm-modal-content-text">
                        {confirmItem?.text}
                    </div>

                    <div className="confirm-modal-content-text-extra">
                        {confirmItem?.textExtra}
                    </div>

                    <Button
                        onClick={onAgree}
                        text={confirmItem.agreeText}
                    >
                        {confirmItem?.agreeText}
                    </Button>

                    <div style={{marginTop: 10}}/>

                    <Button
                        backColor={'#222342'}
                        onClick={onClose}
                        text={confirmItem.dontAgreeText}
                    >
                        {confirmItem?.dontAgreeText}
                    </Button>
                </div>
            </div>
            }
            {children}
        </Context.Provider>
    );
}

export {ConfirmProvider, useConfirm};