import React from "react";

function SearchIcon() {
    return (
        <svg width="28" height="28" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.2">
                <path
                    d="M6 8.99982H3C2.45 8.99982 2 8.54982 2 7.99982C2 7.44982 2.45 6.99982 3 6.99982H6C6.55 6.99982 7 7.44982 7 7.99982C7 8.54982 6.55 8.99982 6 8.99982ZM6 11.9998H3C2.45 11.9998 2 12.4498 2 12.9998C2 13.5498 2.45 13.9998 3 13.9998H6C6.55 13.9998 7 13.5498 7 12.9998C7 12.4498 6.55 11.9998 6 11.9998ZM19.88 18.2898L16.76 15.1698C15.9 15.7298 14.87 16.0498 13.76 15.9898C11.39 15.8798 9.36 14.0298 9.04 11.6798C8.93439 10.8988 9.01444 10.1038 9.27365 9.35945C9.53287 8.61512 9.96394 7.9424 10.5319 7.39589C11.0998 6.84938 11.7886 6.4445 12.5424 6.21409C13.2961 5.98369 14.0936 5.93426 14.87 6.06982C16.82 6.39982 18.44 7.91982 18.87 9.84982C19.2 11.3098 18.88 12.6698 18.17 13.7498L21.3 16.8798C21.69 17.2698 21.69 17.8998 21.3 18.2898C20.91 18.6798 20.27 18.6798 19.88 18.2898ZM17 10.9998C17 9.34982 15.65 7.99982 14 7.99982C12.35 7.99982 11 9.34982 11 10.9998C11 12.6498 12.35 13.9998 14 13.9998C15.65 13.9998 17 12.6498 17 10.9998ZM3 18.9998H11C11.55 18.9998 12 18.5498 12 17.9998C12 17.4498 11.55 16.9998 11 16.9998H3C2.45 16.9998 2 17.4498 2 17.9998C2 18.5498 2.45 18.9998 3 18.9998Z"
                    fill="white"/>
            </g>
        </svg>
    );
}

export default SearchIcon;
