import ERC20ABI from "../assets/ERC20ABI.json";
import MetaMaskSDK from "@metamask/sdk";
import Web3 from "web3";
import {setChangeLoader, setIsGetBalanceUser, setSendHash} from "../redux/swapper";
import {useDispatch, useSelector} from "react-redux";

const MMSDK = new MetaMaskSDK({
    injectProvider: false,
    communicationLayerPreference: "webrtc",
});

const ethereum = MMSDK.getProvider();

export const web3 = new Web3(ethereum);

export const useSwapper = () => {

    const dispatch = useDispatch()
    const {wallet} = useSelector(state => state.auth)

    const sendTransaction = async ({
                                       wallet,
                                       amount,
                                       tokenFrom,
                                       tokenTo,
                                       countFrom,
                                       countTo,
                                       address,
                                   }) => {
        let value = amount * 1e18;

        dispatch(setChangeLoader(true))
        ethereum
            .request({
                method: 'eth_sendTransaction',
                params: [
                    {
                        from: wallet, // The user's active address.
                        to: address, // Required except during contract publications.
                        value: value.toString(16), // Only required to send ether to the recipient from the initiating external account.
                        // gasPrice: '0x09184e72a000', // Customizable by the user during MetaMask confirmation.
                        // gas: '0x9710', // Customizable by the user during MetaMask confirmation.
                    },
                ],
            })
            .then((txHash) => {
                    dispatch(setSendHash({
                        from: {
                            address: tokenFrom || null,
                            amount: Number.parseFloat(countFrom)
                        },
                        to: {
                            address: tokenTo || null,
                            amount: Number.parseFloat(countTo)
                        },
                        txHash: txHash
                    }))
                }
            )
            .catch((error) => console.error(error))

    };

    const sendTransactionToken = async ({
                                            amount,
                                            countFrom,
                                            countTo,
                                            toAddress,
                                            toContractAddress,
                                            fromContractAddress,
                                        }) => {

        dispatch(setChangeLoader(true))

        let value = amount * 1e18;

        let toaddress = toAddress;
        let toamount = BigInt(value);

        let contract = new web3.eth.Contract(ERC20ABI, fromContractAddress);
        let balance = await web3.eth.getBalance(wallet?.accounts[0]);
        let tokenbalance = await contract?.methods?.balanceOf(wallet?.accounts[0])?.call();

        if (tokenbalance < toamount) {
            return {success: false, message: "Low Token balance", trnx: ""}
        }
        let Estimate = await contract.methods.transfer(toaddress, toamount).estimateGas({from: wallet?.accounts[0]})
        if (balance <= Estimate) {
            return {success: false, message: "Low DEL balance", trnx: ""}
        }
        let transaction = await contract.methods.transfer(toaddress, toamount).send({from: wallet?.accounts[0]});

        if (transaction.transactionHash) {
            await dispatch(setSendHash({
                from: {
                    address: fromContractAddress || null,
                    amount: Number.parseFloat(countFrom)
                },
                to: {
                    address: toContractAddress || null,
                    amount: Number.parseFloat(countTo)
                },
                txHash: transaction.transactionHash
            }))
        }

    };

    return {
        sendTransaction,
        sendTransactionToken
    }
}