import React, {useState} from 'react';
import './style.scss'

const PoolsCategories = ({setCurrentPoolCategory, currentPoolCategory}) => {

    const poolsCat = [
        {
            id: 1,
            text: 'Все пулы'
        },
        {
            id: 2,
            text: 'Мои пулы'
        }
    ]

    return (
        <div className="pools-categories">
            {
                poolsCat?.map(item =>
                    <div
                        onClick={() => setCurrentPoolCategory(item.text)}
                        className={`${item.text === currentPoolCategory ? 'pools-categories-active' : 'pools-categories-disabled'}`}
                    >
                        {item.text}
                    </div>
                )
            }
        </div>
    );
};

export default PoolsCategories;