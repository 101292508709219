import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {setIsMob} from "../../redux/adaptive";


const AdapriveController = ({children}) => {

    const dispatch = useDispatch()

    useEffect(() => {
        const handleResize = () => {
            dispatch(setIsMob(window.innerWidth < 1040))
        };

        window.addEventListener('resize', handleResize);
        handleResize(); // Проверка размера страницы при первой загрузке компонента

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    return children;
}

export default AdapriveController;
