import React from 'react';
import WarningIcon from "../../../assets/icons/WarningIcon";
import './style.scss'
const WarningBlock = ({text}) => {
    return (
        <div className="warning-block">
            <div className="warning-block-icon">
                <WarningIcon/>
            </div>
            <div className="warning-block-text">
                {text}
            </div>
        </div>
    );
};

export default WarningBlock;