import React, {useEffect, useRef, useState} from 'react';
import mainLogo from '../../../assets/img/mainLogo.png'
import Nav from "../Nav";
import './style.scss';
import SmallButton from "../SmallButton";
import LanguageIcon from "../../../assets/icons/LanguageIcon";
import DropDown from "../DropDown";
import ArrowToDown from "../../../assets/icons/ArrowToDown";
import FunctionalButton from "../FunctionalButton/inde";
import decimalIcon from '../../../assets/img/decimalIcon.png'
import walletHeader from '../../../assets/img/walletHeader.png'
import testAvatar from '../../../assets/img/testAvatar.png'
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useSwapper} from "../../../hooks/useSwapper";
import {useSocket} from "../../../controllers/SocketProvider";

export const decimalChainId = "0x1344224";

const Header = () => {

    const languages = [
        "ru",
        "eng"
    ]

    const [language, setLanguage] = useState('ru')

    const {wallet} = useSelector(state => state.auth)

    const {
        hasProvider,
        updateWallet,
        handleConnect,
        changeNetwork,
    } = useSocket()



    return (
        <div className="header">
            <div className="header_left-bar">
                <Link to={'/lending'} className="header_left-bar__logo">
                    <img src={mainLogo} alt=""/>
                    <h1>Canyon Swap</h1>
                </Link>

                <div>
                    <Nav/>
                </div>
            </div>
            <div className="header_right-bar">
                <DropDown
                    languages={languages}
                    language={language}
                    setLanguage={setLanguage}
                    startIcon={<LanguageIcon/>}
                    backIcon={<ArrowToDown/>}
                />

                {!hasProvider
                    ?<FunctionalButton
                        text={'Установить metamask'}
                        onClick={() => window.open('https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?hl=ru', '_blank')?.focus()}
                        // icon={decimalIcon}
                    />
                    : wallet?.accounts?.length > 0
                    ?
                    <>
                        <FunctionalButton
                            text={'123'}
                            icon={testAvatar}
                            isGradient={true}
                        />
                        {wallet.chainId !== decimalChainId ? (
                            <FunctionalButton
                                text={'Изменить канал'}
                                // icon={decimalIcon}
                                onClick={changeNetwork}
                            />

                        ) : (
                            <>
                                <FunctionalButton
                                    text={'Decimal'}
                                    icon={decimalIcon}
                                />
                                <FunctionalButton
                                    wallet={wallet}
                                    text={`${wallet.balance} DELL`}
                                    rightText={`${wallet?.accounts[0]}`}
                                    onClick={() => console.log("123")}
                                />
                            </>
                        )}
                    </>
                    :
                    <>
                        <FunctionalButton
                            onClick={handleConnect}
                            text={'Connect wallet'}
                            icon={walletHeader}
                        />
                    </>

                }
            </div>
        </div>

    );
};

export default Header;