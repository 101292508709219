import React, {useContext, useEffect, useState} from 'react';
import "./styles.scss"
import Button from "../../Common/Button";
import {useDispatch, useSelector} from "react-redux";
import {setChangeLoader} from "../../../redux/swapper";
import {Oval} from "react-loader-spinner";

const ModalLoaderSwapper = () => {

    const dispatch = useDispatch()

    const {changeLoader} = useSelector(state => state.swapper)

    return (
        <>
            {changeLoader &&
            <div className="confirm-modal-loader">
                <div className="confirm-modal-loader-content">

                    <div className="confirm-modal-loader-content-title">
                        Подписать транзакцию
                    </div>
                    <div className="confirm-modal-loader-content-loader">
                        <Oval
                            strokeWidth={5}
                            strokeWidthSecondary={5}
                            secondaryColor={'#3F4157'}
                            height="120"
                            width="120"
                            color="#4786FF"
                        />
                    </div>
                    <div className="confirm-modal-loader-content-desc">
                        Пожалуйста, подпишите транзакцию
                        в своем кошельке
                    </div>

                    <Button
                        backColor={'#222342'}
                        onClick={() => dispatch(setChangeLoader(false))}
                        text={'Закрыть'}
                    />
                </div>
            </div>
            }
        </>
    );
}

export default ModalLoaderSwapper;