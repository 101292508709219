import React, {useState} from 'react';
import './style.scss'
import {Link} from "react-router-dom";
import CRYGIcon from '../../../assets/img/CRYGIcon.svg'
import DELIconCycle from '../../../assets/img/DELIconCycle.png'
import ArrowToBottom from "../../../assets/icons/ArrowToBottom";

const ShortCoinCard = ({
                      onPress,
                      bcgColor = '2a2a47',
                      name,
                      balance,
                      token,
                      tokenName,
                      count,
                      isWithhMax
}) => {


    return (
        <div
            className="short-coin-card-item"
            style={{
                backgroundColor: bcgColor
            }}
        >
            { token === '1'
                ?
                <div className="short-coin-card-item-center">
                    <div className="short-coin-card-item-center-token" onClick={onPress}>
                        <img src={CRYGIcon} alt=""/>
                        {tokenName}
                        <ArrowToBottom/>
                    </div>
                    <div className="short-coin-card-item-center-count">
                        1
                    </div>
                </div>
                :
                <div className="short-coin-card-item-center">
                    <div className="short-coin-card-item-center-token" onClick={onPress}>
                        <img src={DELIconCycle} alt=""/>
                        {tokenName}
                        <ArrowToBottom/>
                    </div>
                    <div className="short-coin-card-item-center-count">
                        714.543016
                    </div>
                </div>
            }
        </div>
    );
};

export default ShortCoinCard;