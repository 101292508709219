import React from 'react';
import './style.scss'
import {Link, useLocation} from "react-router-dom";
import HomeIcon from "../../assets/icons/BottomNav/Home";
import FAQIcon from "../../assets/icons/BottomNav/FAQ";
import SystemIcon from "../../assets/icons/BottomNav/System";
import FeedBackIcon from "../../assets/icons/BottomNav/FeedBack";
import LandingIcon from "../../assets/icons/BottomNav/Landing";

const BottomNav = () => {

    const location = useLocation();

    const navElement = [
        {
            href: '/main',
            title: 'Главная',
            icon: <HomeIcon color={'#48496d'}/>,
            iconActive: <HomeIcon color={'#40A5F3'}/>
        },
        {
            href: '/feedback',
            title: 'Обратная связь',
            icon: <FeedBackIcon color={'#48496d'}/>,
            iconActive: <FeedBackIcon color={'#40A5F3'}/>
        },
        {
            href: '/system',
            title: 'System',
            icon: <SystemIcon color={'#48496d'}/>,
            iconActive: <SystemIcon color={'#40A5F3'}/>
        },
        {
            href: '/faq',
            title: 'FAQ',
            icon: <FAQIcon color={'#48496d'}/>,
            iconActive: <FAQIcon color={'#40A5F3'}/>
        },
        {
            href: '/lending',
            title: 'Лендинг',
            icon: <LandingIcon color={'#48496d'}/>,
            iconActive: <LandingIcon color={'#40A5F3'}/>
        },
    ];

    return (
        <ul className="bottom-nav">
            {
                navElement.map((item) =>
                    <li className="bottom-nav__item">
                        {location.pathname.includes(item.href) &&
                        <div className="bottom-nav__item-dote"/>
                        }
                        <Link className="bottom-nav__link" to={item.href}>
                            {
                                location.pathname.includes(item.href) ? item.iconActive : item.icon
                            }
                        </Link>
                    </li>
                )
            }
        </ul>
    );
};

export default BottomNav;