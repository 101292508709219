import React from "react";

function ArrowToRight() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="15"
            fill="none"
            viewBox="0 0 14 15"
        >
            <path
                fill="#fff"
                d="M5.232 1.567l4.323 4.322a2.042 2.042 0 010 2.888L5.232 13.1 3.5 11.367l4.037-4.034L3.503 3.3l1.73-1.733z"
            />
        </svg>
    );
}

export default ArrowToRight;
