import React from "react";

function QuestionIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="none"
            viewBox="0 0 20 20"
        >
            <path
                fill="#fff"
                fillRule="evenodd"
                d="M18.333 10a8.333 8.333 0 11-16.666 0 8.333 8.333 0 1116.666 0zM10 6.46a.938.938 0 00-.938.937.625.625 0 01-1.25 0A2.188 2.188 0 1111.57 8.92c-.077.08-.15.153-.22.223a5.56 5.56 0 00-.476.514c-.183.235-.248.408-.248.552v.625a.625.625 0 01-1.25 0v-.625c0-.546.254-.989.512-1.32.19-.245.43-.483.625-.678.058-.058.113-.112.16-.162A.938.938 0 0010 6.46zm0 7.708a.833.833 0 100-1.666.833.833 0 000 1.666z"
                clipRule="evenodd"
            />
        </svg>
    );
}

export default QuestionIcon;
