import React, {useState} from 'react';
import './style.scss'
import {Link} from "react-router-dom";
import CRYGIcon from '../../../assets/img/CRYGIcon.svg'
import DELIconCycle from '../../../assets/img/DELIconCycle.svg'
import ArrowToBottom from "../../../assets/icons/ArrowToBottom";

const CoinCard = ({
                      onPress,
                      bcgColor = '2a2a47',
                      name,
                      balance,
                      token,
                      tokenCount,
                      tokenName,
                      count,
                      isWithhMax,
                      isEditable = false,
                      value,
                      setMainTokenCount,
                  }) => {


    const onChangeValue = (e) => {

        // let inputValue = parseInt(e);

        setMainTokenCount(e);
    }

    return (
        <div
            className="coin-card-item"
            style={{
                backgroundColor: bcgColor
            }}
        >
            <div className="coin-card-item-top">
                <div className="coin-card-item-top-title">
                    {name}
                </div>
                <div className="coin-card-item-top-info">
                    <div className="coin-card-item-top-info-balance">
                        Баланс: {balance}
                    </div>
                    {isWithhMax &&
                    <div className="coin-card-item-top-info-max">
                        макс
                    </div>
                    }
                </div>
            </div>
            {token === 'CRYG'
                ?
                <div className="coin-card-item-center">
                    <div className="coin-card-item-center-token" onClick={onPress}>
                        <img src={CRYGIcon} alt="" style={{width: 34, height: 34}}/>
                        {tokenName}
                        <ArrowToBottom/>
                    </div>
                </div>
                :
                <div className="coin-card-item-center">
                    <div className="coin-card-item-center-token" onClick={onPress}>
                        <img src={DELIconCycle} alt="" style={{width: 32, height: 32}}/>
                        {tokenName}
                        <ArrowToBottom/>
                    </div>
                </div>
            }

            {isEditable
                ?
                <div className="coin-card-item-bottom">
                    <div className="coin-card-item-bottom-currency">
                        <input
                            value={value}
                            onChange={(e) => onChangeValue(e.target.value)}
                            type="number"
                        />
                    </div>
                    <div className="coin-card-item-bottom-count">
                        ~${String(value * count)?.length > 10 ? String(value * count).slice(0, 10) : value * count}
                    </div>
                </div>
                :
                <div className="coin-card-item-bottom">
                    <div className="coin-card-item-bottom-currency">
                        {String(tokenCount)?.length > 10 ? String(tokenCount).slice(0, 10) : tokenCount}
                    </div>
                    <div className="coin-card-item-bottom-count">
                        ~${String(count * tokenCount)?.length > 10 ? String(count * tokenCount).slice(0, 10) : count * tokenCount}
                    </div>
                </div>
            }
        </div>
    );
};

export default CoinCard;