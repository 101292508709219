import React, {useContext, useEffect, useState} from 'react';
import "./styles.scss"
import Button from "../../Common/Button";
import {useDispatch, useSelector} from "react-redux";
import {setChangeLoader, setSuccessModal} from "../../../redux/swapper";
import {Oval} from "react-loader-spinner";
import SuccessSwapIcon from "../../../assets/icons/SuccessSwapIcon";
import ErrorEmoji from "../../../assets/icons/ErrorEmoji";

const ModalError = ({
                        isVisible,
                        onClose,
                        text,

                    }) => {

    return (
        <>
            {isVisible &&
            <div className="error-modal">
                <div className="error-modal-content">

                    <div className="error-modal-content-title">
                        Ошибка
                    </div>

                    <div className="error-modal-content-icon">
                        <ErrorEmoji/>
                    </div>

                    <div className="error-modal-content-desc">
                        {text}
                    </div>

                    <div style={{marginTop: 11}}/>
                    <Button
                        backColor={'#222342'}
                        onClick={() => onClose()}
                        text={'Закрыть'}
                    />
                </div>
            </div>
            }
        </>
    );
}

export default ModalError;