import { Navigate } from "react-router-dom";
import MainLayout from "../layouts/MainLayout";
import NotFound from "../pages/NotFound";
import Swap from "../pages/Main/Swap";
import UserAgreement from "../pages/System/UserAgreement";
import PrivacyPolicy from "../pages/System/PrivacyPolicy";
import FAQ from "../pages/FAQ";
import FeedBack from "../pages/FeedBack";
import Landing from "../pages/Landing";
import System from "../pages/System/System";
import Pools from "../pages/Main/Pools";
import СonfirmSwapper from "../pages/Main/СonfirmSwapper";
import ChooseToken from "../pages/Main/ChooseToken";
import AddLiquidity from "../pages/Main/AddLiquidity";
import TakeLiquidity from "../pages/Main/TakeLiquidity";
import ConfirmPools from "../pages/Main/ConfirmPools";

const navigation = [
	{
		path: '/',
		element: <MainLayout/>,
		children: [
			{ path: 'lending', element: <Landing /> },

			{ path: 'main/swap', element: <Swap /> },
			{ path: 'main/swap/confirm-swapper', element: <СonfirmSwapper /> },
			{ path: 'main/swap/choose-token', element: <ChooseToken /> },

			{ path: 'main/pools', element: <Pools /> },
			{ path: 'main/pools/add-liquidity', element: <AddLiquidity /> },
			{ path: 'main/pools/take-liquidity', element: <TakeLiquidity /> },
			{ path: 'main/pools/choose-token', element: <ChooseToken /> },
			{ path: 'main/pools/confirm-pools', element: <ConfirmPools /> },

			{ path: 'faq', element: <FAQ /> },

			{ path: 'feedback', element: <FeedBack /> },

			{ path: 'system', element: <System /> },
			{ path: 'system/user-agreements', element: <UserAgreement /> },
			{ path: 'system/privacy-policy', element: <PrivacyPolicy /> },

			{ path: '/', element: <Navigate to="/lending"/> },
			{ path: '/main', element: <Navigate to="/main/swap"/> },
			{ path: '404', element: <NotFound/> },
			{ path: '*', element: <Navigate to="/404" /> },
		]
	}
];

export default navigation;

