import React from "react";

function HomeIcon({color = '#222342'}) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="none"
            viewBox="0 0 32 32"
        >
            <path
                fill={color}
                d="M24.707 23.293a1.001 1.001 0 010 1.415C24.573 24.84 21.352 28 16 28c-2.92 0-5.766-1.137-8.285-3.301l-2.008 2.008A1 1 0 014 26v-6a1 1 0 011-1h6a1 1 0 01.707 1.707L9.136 23.28C10.76 24.644 13.104 26 16 26c4.534 0 7.266-2.68 7.293-2.707a1.001 1.001 0 011.415 0zm2.675-18.217a1 1 0 00-1.09.217L24.285 7.3C21.766 5.138 18.92 4 16 4c-5.352 0-8.572 3.159-8.707 3.293a1 1 0 101.414 1.414C8.734 8.68 11.467 6 16 6c2.896 0 5.24 1.356 6.865 2.72l-2.572 2.572A1 1 0 0021 13h6a1 1 0 001-1V6a1 1 0 00-.617-.924z"
            />
        </svg>
    );
}

export default HomeIcon;
