import React, {useState} from 'react';
import './style.scss'
import DELIconCycle from "../../../assets/img/DELIconCycle.png";
import CRYGIcon from "../../../assets/img/CRYGIcon.svg";
import {setMainToken, setSecondaryToken} from "../../../redux/swapper";
import {useLocation} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";

const TokenItems = ({tokenItemsData, onCloseModal, isModal = false, isMain}) => {

    const search = useLocation().search
    const dispatch = useDispatch()
    const navigate = useNavigate();

    const {mainToken, secondaryToken, userBalance, tokens} = useSelector(state => state.swapper)

    const imgs = {
        DEL: DELIconCycle,
        CRYG: CRYGIcon
    }

    const slicedPrice = (string) => {
        return string?.length > 10 ? string.slice(0, 10) : string
    }

    const onChooseToken = (item) => {
        if (search.includes('?isMain=true')) {
            if (item?.symbol === secondaryToken?.symbol) {
                dispatch(setMainToken(item))
                dispatch(setSecondaryToken(tokens?.find(findItem => findItem?.symbol !== item?.symbol)))
            } else {
                dispatch(setMainToken(item))
            }
            navigate('/main/swap')
            if (isModal) {
                onCloseModal()
            }
        } else if (search.includes('?isMain=false')) {
            if (item?.symbol === mainToken?.symbol) {
                dispatch(setSecondaryToken(item))
                dispatch(setMainToken(tokens?.find(findItem => findItem?.symbol !== item?.symbol)))
            } else {
                dispatch(setSecondaryToken(item))
            }
            navigate('/main/swap')
            if (isModal) {
                onCloseModal()
            }
        } else if (isModal && isMain) {
            if (item?.symbol === secondaryToken?.symbol) {
                dispatch(setMainToken(item))
                dispatch(setSecondaryToken(tokens?.find(findItem => findItem?.symbol !== item?.symbol)))
            } else {
                dispatch(setMainToken(item))
            }
            onCloseModal()
        } else if (isModal && !isMain) {
            if (item?.symbol === mainToken?.symbol) {
                dispatch(setSecondaryToken(item))
                dispatch(setMainToken(tokens?.find(findItem => findItem?.symbol !== item?.symbol)))
            } else {
                dispatch(setSecondaryToken(item))
            }
            onCloseModal()
        }
    }

    return (
        <div className="token-items">
            {
                tokenItemsData.map(item =>
                    <div className="token-items-item">
                        <div
                            className="token-items-item-info"
                            onClick={() => onChooseToken(item)}
                        >
                            <img src={imgs[item?.symbol]} alt=""/>
                            {item?.fullName}
                        </div>
                        <div className="token-items-item-count">
                            {slicedPrice(String(item?.price))}
                        </div>
                    </div>
                )
            }
        </div>
    );
};

export default TokenItems;