import React, {useState} from 'react';
import './style.scss'
import FAQItem from "../../components/FAQ/FAQItem";
import faqbck from '../../assets/img/faqbck.png';

const FAQ = () => {

    const [faqList, setFaqList] = useState([
        {
            id: 1,
            title: 'Вопрос 1',
            isOpen: false,
            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In sit amet commodo orci, non tempus metus. Suspendisse vitae mauris enim. Pellentesque porta urna egestas elementum tempor. Vivamus erat augue, ullamcorper in accumsan sed, ornare eget ligula. Nunc elementum arcu vitae fermentum mattis.'
        },
        {
            id: 2,
            title: 'Вопрос 1',
            isOpen: false,
            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In sit amet commodo orci, non tempus metus. Suspendisse vitae mauris enim. Pellentesque porta urna egestas elementum tempor. Vivamus erat augue, ullamcorper in accumsan sed, ornare eget ligula. Nunc elementum arcu vitae fermentum mattis.'
        },
        {
            id: 3,
            title: 'Вопрос 1',
            isOpen: false,
            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In sit amet commodo orci, non tempus metus. Suspendisse vitae mauris enim. Pellentesque porta urna egestas elementum tempor. Vivamus erat augue, ullamcorper in accumsan sed, ornare eget ligula. Nunc elementum arcu vitae fermentum mattis.'
        },
        {
            id: 4,
            title: 'Вопрос 1',
            isOpen: false,
            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In sit amet commodo orci, non tempus metus. Suspendisse vitae mauris enim. Pellentesque porta urna egestas elementum tempor. Vivamus erat augue, ullamcorper in accumsan sed, ornare eget ligula. Nunc elementum arcu vitae fermentum mattis.'
        },
        {
            id: 5,
            title: 'Вопрос 1',
            isOpen: false,
            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In sit amet commodo orci, non tempus metus. Suspendisse vitae mauris enim. Pellentesque porta urna egestas elementum tempor. Vivamus erat augue, ullamcorper in accumsan sed, ornare eget ligula. Nunc elementum arcu vitae fermentum mattis.'
        },
        {
            id: 6,
            title: 'Вопрос 1',
            isOpen: false,
            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In sit amet commodo orci, non tempus metus. Suspendisse vitae mauris enim. Pellentesque porta urna egestas elementum tempor. Vivamus erat augue, ullamcorper in accumsan sed, ornare eget ligula. Nunc elementum arcu vitae fermentum mattis.'
        },
    ])


    const onOpenOrClose = (id) =>{
        let map = faqList.map(item => item.id === id ? {...item, isOpen: !item.isOpen} : item);
        setFaqList(map)
    }

    return (
        <div className="faq">
            {
                faqList.map(item =>
                    <FAQItem
                        title={item.title}
                        text={item.text}
                        isOpen={item.isOpen}
                        key={item.id}
                        onPress={() => onOpenOrClose(item.id)}
                    />
                )
            }
            <img
                className="faq-img"
                src={faqbck}
                alt=""
            />
        </div>
    );
};

export default FAQ;