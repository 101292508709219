import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import {io} from "socket.io-client";

const root = ReactDOM.createRoot(document.getElementById('root'));

export const IO = io("https://canyonswap.backend.wtsdemo.ru");

root.render(
	<BrowserRouter>
		<App />
	</BrowserRouter>
);

