import React from 'react';

const ErrorEmoji = () => {
    return (
        <div>
            <svg width="117" height="117" viewBox="0 0 117 117" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_126_2979)">
                    <g filter="url(#filter0_d_126_2979)">
                        <path d="M58.5 3.65625C28.2043 3.65625 3.65625 28.2043 3.65625 58.5C3.65625 88.7957 28.2043 113.344 58.5 113.344C88.7957 113.344 113.344 88.7957 113.344 58.5C113.344 28.2043 88.7957 3.65625 58.5 3.65625ZM58.5 108.773C30.7783 108.773 8.22656 86.2217 8.22656 58.5C8.22656 30.7783 30.7783 8.22656 58.5 8.22656C86.2199 8.22656 108.773 30.7783 108.773 58.5C108.773 60.9808 108.585 63.4177 108.236 65.8052C106.704 55.458 97.8339 46.1236 97.8339 46.1236C97.8339 46.1236 87.1796 57.2989 87.1796 68.7466C87.1796 80.3607 98.8668 83.3643 104.927 77.7721C97.3495 95.9546 79.3973 108.773 58.5 108.773Z" fill="#EB5757"/>
                    </g>
                    <g filter="url(#filter1_d_126_2979)">
                        <path d="M70.3789 73.125C75.4271 73.125 79.5195 69.0326 79.5195 63.9844C79.5195 58.9361 75.4271 54.8438 70.3789 54.8438C65.3307 54.8438 61.2383 58.9361 61.2383 63.9844C61.2383 69.0326 65.3307 73.125 70.3789 73.125Z" fill="#EB5757"/>
                    </g>
                    <g filter="url(#filter2_d_126_2979)">
                        <path d="M28.332 73.125C33.3803 73.125 37.4727 69.0326 37.4727 63.9844C37.4727 58.9361 33.3803 54.8438 28.332 54.8438C23.2838 54.8438 19.1914 58.9361 19.1914 63.9844C19.1914 69.0326 23.2838 73.125 28.332 73.125Z" fill="#EB5757"/>
                    </g>
                    <g filter="url(#filter3_d_126_2979)">
                        <path d="M40.2044 39.4508C41.1934 38.5934 38.4293 35.6831 37.6194 36.3906C34.6965 38.8579 31.2654 40.6504 27.5706 41.6404C23.8758 42.6303 20.0082 42.7934 16.2432 42.1181C15.1865 41.9115 14.2542 45.8127 15.5393 46.0595C19.8836 46.8394 24.3464 46.6515 28.6098 45.5092C32.8731 44.3669 36.832 42.2983 40.2044 39.4508ZM82.4725 41.8402C74.8528 43.2076 67.0157 41.1089 61.0999 36.1127C60.29 35.4015 57.5259 38.3156 58.5186 39.173C61.891 42.0203 65.8499 44.0888 70.1132 45.2311C74.3766 46.3734 78.8393 46.5614 83.1836 45.7816C84.4688 45.5348 83.5328 41.6318 82.4725 41.8402ZM69.6043 86.5251C59.0633 83.6933 47.5772 85.7426 38.6541 92.035C36.4513 93.6237 40.5792 99.3969 42.7711 97.7973C48.6631 93.6511 58.0396 90.7882 67.7433 93.3513C70.3447 94.0405 72.3794 87.3038 69.6043 86.5251Z" fill="#EB5757"/>
                    </g>
                </g>
                <defs>
                    <filter id="filter0_d_126_2979" x="-4.34375" y="-4.34375" width="125.688" height="125.688" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                        <feOffset/>
                        <feGaussianBlur stdDeviation="4"/>
                        <feComposite in2="hardAlpha" operator="out"/>
                        <feColorMatrix type="matrix" values="0 0 0 0 0.921569 0 0 0 0 0.341176 0 0 0 0 0.341176 0 0 0 1 0"/>
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_126_2979"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_126_2979" result="shape"/>
                    </filter>
                    <filter id="filter1_d_126_2979" x="53.2383" y="46.8438" width="34.2812" height="34.2812" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                        <feOffset/>
                        <feGaussianBlur stdDeviation="4"/>
                        <feComposite in2="hardAlpha" operator="out"/>
                        <feColorMatrix type="matrix" values="0 0 0 0 0.921569 0 0 0 0 0.341176 0 0 0 0 0.341176 0 0 0 1 0"/>
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_126_2979"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_126_2979" result="shape"/>
                    </filter>
                    <filter id="filter2_d_126_2979" x="11.1914" y="46.8438" width="34.2812" height="34.2812" filterUnits="userSpaceOnUse" coloIinterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                        <feOffset/>
                        <feGaussianBlur stdDeviation="4"/>
                        <feComposite in2="hardAlpha" operator="out"/>
                        <feColorMatrix type="matrix" values="0 0 0 0 0.921569 0 0 0 0 0.341176 0 0 0 0 0.341176 0 0 0 1 0"/>
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_126_2979"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_126_2979" result="shape"/>
                    </filter>
                    <filter id="filter3_d_126_2979" x="6.91504" y="28.002" width="84.8916" height="78.0723" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                        <feOffset/>
                        <feGaussianBlur stdDeviation="4"/>
                        <feComposite in2="hardAlpha" operator="out"/>
                        <feColorMatrix type="matrix" values="0 0 0 0 0.921569 0 0 0 0 0.341176 0 0 0 0 0.341176 0 0 0 1 0"/>
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_126_2979"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_126_2979" result="shape"/>
                    </filter>
                    <clipPath id="clip0_126_2979">
                        <rect width="117" height="117" fill="white"/>
                    </clipPath>
                </defs>
            </svg>

        </div>
    );
};

export default ErrorEmoji;