import React, {useState} from 'react';
import './style.scss'
import Wrapper from "../../../components/Main/Wrapper";
import SwaperNav from "../../../components/Main/SwaperNav";
import Button from "../../../components/Common/Button";
import CoinCard from "../../../components/Main/CoinCard";
import BigArrowToBottom from "../../../assets/icons/BigArrowToBottom";
import WarningIcon from "../../../assets/icons/WarningIcon";
import WalletIcon from "../../../assets/icons/WalletIcon";
import ArrowToBottom from "../../../assets/icons/ArrowToBottom";

const ExtraInfo = ({
                       isBurger = false,
                       changeText,
                       keyText,
                       valueText,
                       valueColor = '#bbbac2',
                       extraValue,
                       extraValueColor = '#bbbac2'
                   }) => {

    return (
        <div className="extra-info">
            <div className="extra-info-value">
                <p style={{
                    marginRight: isBurger ? 5 : 0
                }}>
                    {keyText}
                </p>
                {isBurger &&
                <ArrowToBottom/>
                }
            </div>
            <div className="extra-info-key">
                {!!changeText &&
                <div className="extra-info-key-change-button">
                    {changeText}
                </div>
                }
                <div
                    style={{
                        color: valueColor
                    }}
                    className="extra-info-key-text"
                >
                    {valueText}
                </div>
                {!!extraValue &&
                <div
                    style={{
                        color: extraValueColor
                    }}
                    className="extra-info-key-text-extra"
                >
                    {extraValue}
                </div>
                }


            </div>
        </div>
    );
};

export default ExtraInfo;