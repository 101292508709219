import React, {useState} from 'react';
import BackButton from "../../../components/Common/BackButton";
import CustomAreaChart from "../../../components/Main/CustomAreaChart";
import CustomPieChart from "../../../components/Main/CustomPieChart";
import Button from "../../../components/Common/Button";
import MobHeader from "../../../components/Main/MobHeader";
import Wrapper from "../../../components/Main/Wrapper";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import ModalLiquidityAdd from "../../../components/Main/ModalLiquidityAdd";
import ModalLiquidityTake from "../../../components/Main/ModalLiquidityTake";
import CRYG from '../../../assets/img/CRYGIconCycle.png'
import DEL from '../../../assets/img/DELIconCycle.png'

const ConfirmPools = () => {

    const navigate = useNavigate();

    const [isConnectedWallet, setIsConnectedWallet] = useState(false)

    const {isMob} = useSelector(state => state.adaptive)

    const [modalOpenAdd, setModalOpenAdd] = useState(false);
    const openModalAdd = () => {
        setModalOpenAdd(true);
    };

    const closeModalAdd = () => {
        setModalOpenAdd(false);
    };

    const [modalOpenTake, setModalOpenTake] = useState(false);
    const openModalTake = () => {
        setModalOpenTake(true);
    };

    const closeModalTake = () => {
        setModalOpenTake(false);
    };

    return (
        <div className="swaper-wrapper">
            <MobHeader/>
            <Wrapper minHeight={350}>
                <div>
                    <div className="pools-confirm">
                        <BackButton
                            onPress={() => navigate(-1)}
                            title={'Подтвердить обмен'}
                        />
                        <div style={{marginTop: 15}}/>

                        <div className="pools-confirm-from-to">
                            <div className="pools-confirm-from-to-left">
                                <div className="pools-confirm-from-to-left-imgs">
                                    <img src={DEL} alt="" className="pools-confirm-from-to-left-imgs-img-first"/>
                                    <img src={CRYG} alt="" className="pools-confirm-from-to-left-imgs-img-second"/>
                                </div>
                                <div className="pools-confirm-from-to-left-text">
                                    {`CRYG/DEL`}
                                </div>
                            </div>
                            <div className="pools-confirm-from-to-right">
                                <div className="pools-confirm-from-to-right-text">
                                    {`28 640,77/28 640,77`}
                                </div>
                            </div>
                        </div>

                        <div style={{marginTop: 10}}/>

                        <div className="pools-confirm-valume">
                            <div className="pools-confirm-valume-title">
                                Объем торгов
                            </div>
                            <div className="pools-confirm-valume-value">
                                0,24
                            </div>
                        </div>

                        <div style={{marginTop: 20}}/>

                        <CustomAreaChart/>

                        <div style={{marginTop: 20}}/>

                        <CustomPieChart/>

                        <div style={{marginTop: 10}}/>

                        <div className="pools-confirm-stats">
                            <div className="pools-confirm-stats-stat1">
                                <div className="pools-confirm-stats-stat-title">
                                    Доходность
                                </div>
                                <div className="pools-confirm-stats-stat-value">
                                    0,24%
                                </div>
                            </div>
                            <div className="pools-confirm-stats-stat2">
                                <div className="pools-confirm-stats-stat-title">
                                    Объем торгов за 24 часа
                                </div>
                                <div className="pools-confirm-stats-stat-value">
                                    0,24%
                                </div>
                            </div>
                        </div>

                        <div className="pools-confirm-income">
                            <div className="pools-confirm-income-title">
                                Мой доход
                            </div>
                            <div className="pools-confirm-income-value">
                                1236 CRYG
                            </div>
                        </div>

                        <div style={{marginTop: 10}}/>

                        <Button
                            text={'Забрать доход'}
                            onClick={() => setIsConnectedWallet(true)}
                        />

                        <div style={{marginTop: 10}}/>

                        <Button
                            backColor={'#222342'}
                            text={'Добавить ликвидность'}
                            onClick={() => isMob ? openModalAdd() : navigate('/main/pools/add-liquidity')}

                        />

                        <div style={{marginTop: 10}}/>

                        <Button
                            backColor={'#222342'}
                            text={'Забрать ликвидность'}
                            onClick={() => isMob ? openModalTake() : navigate('/main/pools/add-liquidity')}
                        />

                    </div>
                </div>
            </Wrapper>
            <ModalLiquidityAdd isOpen={modalOpenAdd} onClose={closeModalAdd} />
            <ModalLiquidityTake isOpen={modalOpenTake} onClose={closeModalTake} />
        </div>
    );
};

export default ConfirmPools;