import {createSlice} from '@reduxjs/toolkit';
import {configureTransport} from '../../api/transport';

export const swapperSlice = createSlice({
    name: 'swapper',
    initialState: {
        tokens: [],
        mainToken: {},
        mainCount: 1,
        secondaryToken: {},
        userBalance: [],
        dataForChange: {},
        changeLoader: false,
        isGetBalanceUser: true,
        sendHash: false,
        address: null,
        successModal: false,
    },
    reducers: {
        setTokens: (state, action) => {
            state.tokens = action.payload;
        },
        setMainToken: (state, action) => {
            state.mainToken = action.payload;
        },
        setMainCount: (state, action) => {
            state.mainCount = action.payload;
        },
        setSecondaryToken: (state, action) => {
            state.secondaryToken = action.payload;
        },
        setUserBalance: (state, action) => {
            state.userBalance = action.payload;
        },
        setDataForChange: (state, action) => {
            state.dataForChange = action.payload;
        },
        setChangeLoader: (state, action) => {
            state.changeLoader = action.payload;
        },
        setSuccessModal: (state, action) => {
            state.successModal = action.payload;
        },
        setIsGetBalanceUser: (state, action) => {
            state.isGetBalanceUser = action.payload;
        },
        setSendHash: (state, action) => {
            state.sendHash = action.payload;
        },
        setAddress: (state, action) => {
            state.address = action.payload;
        },
    },
});

export const {
    setTokens,
    setMainToken,
    setMainCount,
    setSecondaryToken,
    setUserBalance,
    setDataForChange,
    setChangeLoader,
    setIsGetBalanceUser,
    setSendHash,
    setAddress,
    setSuccessModal
} = swapperSlice.actions;

export default swapperSlice.reducer;
