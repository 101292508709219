import {useEffect, useState} from "react";
import {get} from "../../api/request";
import {setIsGetBalanceUser, setMainCount, setMainToken, setSecondaryToken, setUserBalance} from "../../redux/swapper";
import {useDispatch, useSelector} from "react-redux";

const SwapperController = ({children}) => {

    const dispatch = useDispatch()
    const {wallet} = useSelector(state => state.auth)
    const {mainToken, secondaryToken, userBalance, tokens, mainCount, isGetBalanceUser} = useSelector(state => state.swapper)

    const [isFirstTime, setIsFirstTime] = useState(true)

    useEffect(() => {
        if (tokens.length > 0 && isFirstTime) {
            dispatch(setMainToken(tokens?.find(item => item?.symbol === 'DEL')))
            dispatch(setSecondaryToken(tokens?.find(item => item?.symbol === 'CRYG')))
            setIsFirstTime(false)
        }
    }, [tokens])


    useEffect(() => {
        if (isGetBalanceUser){
            get(`/blockchain/wallet/info/${wallet?.accounts[0]}`)
                .then((response) => {
                    dispatch(setUserBalance(response?.data?.result))
                })
                .catch((e) => {
                    dispatch(setUserBalance([]))
                })
                .finally(() => {
                    dispatch(setIsGetBalanceUser(false))
                })
        }
    }, [wallet?.accounts[0], isGetBalanceUser])

    return children;
}

export default SwapperController;
