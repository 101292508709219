import React, {useState, useEffect} from 'react';
import "./index.scss";
import {Outlet} from 'react-router-dom';
import Header from "../../components/HeaderComponents/Header";
import Footer from "../../components/Footer";
import BottomNav from "../../components/BottomNav";

const MainLayout = () => {


    return (
        <div className="container">
            <div className="main-header">
                <Header/>
            </div>
            <div className="main-wrapper">
                <Outlet/>
            </div>
            <div className="main-footer">
                <Footer/>
            </div>
            <div className="main-footer_mob">
                <BottomNav/>
            </div>
        </div>
    );
};

export default MainLayout;
