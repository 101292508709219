import React, {useEffect, useState} from "react";
import './styles.scss'
import BackButton from "../../Common/BackButton";
import ShortCoinCard from "../ShortCoinCard";
import Button from "../../Common/Button";
import Input from "../../Common/Input";
import SearchIcon from "../../../assets/icons/SearchIcon";
import TokenIcons from "../TokenIcons";
import TokenItems from "../TokenItems";
import CRYGIcon from '../../../assets/img/CRYGIcon.svg'
import DELIconCycle from '../../../assets/img/DELIconCycle.svg'
import {useLocation} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {setMainToken, setSecondaryToken} from "../../../redux/swapper";

const ChooseTokenModal = ({ isOpen, onClose, isSecondModal = false, isMain }) => {

    const {tokens} = useSelector(state => state.swapper)

    const [searchValue, setSearchValue] = useState()

    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    }, [isOpen]);


    return (
        <>
            {isOpen &&
            <div
                style={{
                    backgroundColor: isSecondModal ? 'rgba(0, 0, 0, 0.0)' : 'rgba(0, 0, 0, 0.5)'
                }}
                className="modal-overlay-token"
                onClick={onClose}
            />
            }

            <div className={`modal-token ${isOpen ? 'open' : ''}`}>
                <div className="modal-content-token">
                    <div className="check-token">
                        <BackButton
                            onPress={onClose}
                            title={'Выберите токен'}
                        />

                        <div style={{marginTop: 24}}/>

                        <Input
                            isWithBoxShadow={true}
                            bcgColor={'#222342'}
                            borderColor={'#222342'}
                            placeholder='Search by name or paste number'
                            name='searchValue'
                            value={searchValue}
                            onChange={(text) => setSearchValue(text.target.value)}
                            leftIcon={<SearchIcon/>}
                        />

                        <div style={{marginTop: 20}}/>

                        <div className="check-token-coin-icons">
                            <TokenIcons
                                isModal={true}
                                isMain={isMain}
                                onCloseModal={onClose}
                                tokenIconsData={tokens}
                            />
                        </div>

                        <div className="check-token-line"/>

                        <div className="check-token-coin-items">
                            <TokenItems
                                isModal={true}
                                isMain={isMain}
                                onCloseModal={onClose}
                                tokenItemsData={tokens}
                            />
                        </div>

                    </div>
                </div>
            </div>
        </>

    );
};

export default ChooseTokenModal