import React from "react";

function ArrowToTop() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="15"
            height="15"
            fill="none"
            viewBox="0 0 15 15"
        >
            <path
                fill="#fff"
                d="M1.792 8.92l4.323-4.322a2.042 2.042 0 012.888 0l4.322 4.323-1.733 1.732L7.56 6.616 3.525 10.65 1.792 8.92z"
            />
        </svg>
    );
}

export default ArrowToTop;
