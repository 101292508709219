import React, {useState} from 'react';
import BackButton from "../../../components/Common/BackButton";
import Input from "../../../components/Common/Input";
import SearchIcon from "../../../assets/icons/SearchIcon";
import TokenIcons from "../../../components/Main/TokenIcons";
import TokenItems from "../../../components/Main/TokenItems";
import MobHeader from "../../../components/Main/MobHeader";
import Wrapper from "../../../components/Main/Wrapper";
import CRYGIcon from '../../../assets/img/CRYGIcon.svg'
import DELIconCycle from '../../../assets/img/DELIconCycle.png'
import {useNavigate, useSearchParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

const ChooseToken = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch()

    const {tokens} = useSelector(state => state.swapper)

    const [searchValue, setSearchValue] = useState()

    return (
        <div className="swaper-wrapper">
            <MobHeader/>
            <Wrapper>
                <div>
                    <div className="check-token">
                        <BackButton
                            onPress={() => navigate(-1)}
                            title={'Выберите токен'}
                        />

                        <div style={{marginTop: 15}}/>

                        <Input
                            isWithBoxShadow={true}
                            bcgColor={'#222342'}
                            borderColor={'#222342'}
                            placeholder='Search by name or paste number'
                            name='searchValue'
                            value={searchValue}
                            onChange={(text) => setSearchValue(text.target.value)}
                            leftIcon={<SearchIcon/>}
                        />

                        <div style={{marginTop: 20}}/>

                        <div className="check-token-coin-icons">
                            <TokenIcons
                                tokenIconsData={tokens}
                            />
                        </div>

                        <div className="check-token-line"/>

                        <div className="check-token-coin-items">
                            <TokenItems
                                tokenItemsData={tokens}
                            />
                        </div>

                    </div>
                </div>
            </Wrapper>
        </div>
    );
};

export default ChooseToken;