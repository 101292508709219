import React from 'react';

const BigWallet = () => {
    return (
        <svg width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.7">
                <path d="M30 50H50" stroke="white" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
                <path
                    d="M104.165 55H91.155C82.23 55 75 61.715 75 70C75 78.285 82.235 85 91.15 85H104.165C104.585 85 104.79 85 104.965 84.99C107.665 84.825 109.815 82.83 109.99 80.325C110 80.165 110 79.97 110 79.585V60.415C110 60.03 110 59.835 109.99 59.675C109.81 57.17 107.665 55.175 104.965 55.01C104.79 55 104.585 55 104.165 55Z"
                    stroke="white" strokeWidth="5"/>
                <path
                    d="M104.825 55C104.435 45.64 103.185 39.9 99.14 35.86C93.285 30 83.855 30 65 30H50C31.145 30 21.715 30 15.86 35.86C10 41.715 10 51.145 10 70C10 88.855 10 98.285 15.86 104.14C21.715 110 31.145 110 50 110H65C83.855 110 93.285 110 99.14 104.14C103.185 100.1 104.44 94.36 104.825 85"
                    stroke="white" strokeWidth="5"/>
                <path
                    d="M30 29.9997L48.675 17.6147C51.3013 15.9064 54.367 14.9971 57.5 14.9971C60.633 14.9971 63.6987 15.9064 66.325 17.6147L85 29.9997"
                    stroke="white" strokeWidth="5" strokeLinecap="round"/>
                <path d="M89.9551 70H90.0096" stroke="white" strokeWidth="5" strokeLinecap="round"
                      strokeLinejoin="round"/>
            </g>
        </svg>

    );
};

export default BigWallet;