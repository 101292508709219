import React from "react";

function ArrowToLeft() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="none"
            viewBox="0 0 20 20"
        >
            <path
                fill="#fff"
                d="M12.03 18.476L5.855 12.3a2.916 2.916 0 010-4.126L12.03 2l2.474 2.476-5.767 5.762L14.5 16l-2.47 2.476z"
            />
        </svg>
    );
}

export default ArrowToLeft;
