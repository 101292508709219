import React, {useState} from 'react';
import './style.scss'

const Wrapper = ({children, minHeight = 500}) => {

    return (
        <div className="swaper-block"
        style={{
            minHeight: minHeight
        }}
        >
            {children}
        </div>
    );
};

export default Wrapper;