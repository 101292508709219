import React from "react";

function ArrowToDown() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="11"
            height="10"
            fill="none"
            viewBox="0 0 11 10"
        >
            <g clipPath="url(#clip0_158_1470)" opacity="0.7">
                <path
                    fill="#fff"
                    d="M10.155 3.985L7.068 7.073a1.458 1.458 0 01-2.063 0L1.917 3.985l1.238-1.237L6.036 5.63l2.881-2.88 1.238 1.234z"
                />
            </g>
            <defs>
                <clipPath id="clip0_158_1470">
                    <path
                        fill="#fff"
                        d="M0 0H10V10H0z"
                        transform="rotate(-90 5.459 4.541)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
}

export default ArrowToDown;
