import React, {useEffect, useState} from "react";
import './styles.scss'
import BackButton from "../../Common/BackButton";
import ShortCoinCard from "../ShortCoinCard";
import Button from "../../Common/Button";
import ChooseTokenModal from "../ChooseTokenModal";
import CircleArrowToTop from "../../../assets/icons/CircleArrowToTop";
import {useConfirm} from "../../../controllers/ModalConfirm";

const ModalLiquidityTake = ({isOpen, onClose}) => {

    const [tokenModalOpen, setTokenModalOpen] = useState(false);
    const confirmU = useConfirm();

    const openTokenModal = () => {
        setTokenModalOpen(true);
    };

    const closeTokenModal = () => {
        setTokenModalOpen(false);
    };


    const onTakeLiquidity = () =>{
        confirmU({
            isVisible: true,
            title: 'Транзакция отправлена',
            img: <CircleArrowToTop/>,
            text: 'С вашего кошелька будет списано',
            textExtra: '100 CRYG',
            agreeText: 'Подтвердить',
            dontAgreeText: 'Отменить',
            onPress: () => onClose
        })
    }

    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    }, [isOpen]);

    return (
        <>
            {isOpen && <div className="modal-overlay" onClick={onClose}/>}
            <div className={`modal ${isOpen ? 'open' : ''}`}>
                <div className="modal-content">
                    <BackButton
                        onPress={onClose}
                        title={'Забрать ликвидность'}
                    />

                    <div className="modal-content-title">
                        Внесите суммы
                    </div>

                    <ShortCoinCard
                        bcgColor={'#282844'}
                        name={'Вы покупаете'}
                        balance={0}
                        isWithhMax={false}
                        token={'1'}
                        tokenName={'CRYG'}
                        count={'~$333.73'}
                        onPress={openTokenModal}
                    />

                    <div style={{marginTop: 10}}/>

                    <ShortCoinCard
                        bcgColor={'#4b4a61'}
                        name={'Вы покупаете'}
                        balance={0}
                        isWithhMax={false}
                        token={'0'}
                        tokenName={'DELL'}
                        count={'~$333.73'}
                        onPress={openTokenModal}
                    />

                    <div style={{marginTop: 20}}/>

                    <Button
                        text={'Забрать'}
                        onClick={onTakeLiquidity}
                    />
                </div>
            </div>
            <ChooseTokenModal isOpen={tokenModalOpen} onClose={closeTokenModal} isSecondModal={true}/>
        </>

    );
};

export default ModalLiquidityTake