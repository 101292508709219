import React, {useEffect, useState} from 'react';
import DropDown from "../../HeaderComponents/DropDown";
import LanguageIcon from "../../../assets/icons/LanguageIcon";
import ArrowToDown from "../../../assets/icons/ArrowToDown";
import FunctionalButton from "../../HeaderComponents/FunctionalButton/inde";
import testAvatar from "../../../assets/img/testAvatar.png";
import decimalIcon from "../../../assets/img/decimalIcon.png";
import './styles.scss'

const MobHeader = () => {

    const languages = [
        "ru",
        "eng"
    ]

    const [language, setLanguage] = useState('ru')


    return (
        <div className="header-mob">

            <div className="header-mob-top">
                <DropDown
                    languages={languages}
                    language={language}
                    setLanguage={setLanguage}
                    startIcon={<LanguageIcon/>}
                    backIcon={<ArrowToDown/>}
                />
                <FunctionalButton
                    text={'123'}
                    icon={testAvatar}
                    isGradient={true}
                />
                <FunctionalButton
                    text={'Decimal'}
                    icon={decimalIcon}
                />
            </div>

            <div className="header-mob-bottom">
                <FunctionalButton
                    text={'20.453 CRYG'}
                    rightText={'0x1cA1234090827169099872d5d'}
                />
            </div>


        </div>
    );
};

export default MobHeader;