import React from "react";

function FeedBackIcon({color = '#222342'}) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="none"
            viewBox="0 0 32 32"
        >
            <path
                fill={color}
                d="M26.667 8h-1.334v10.667c0 .733-.6 1.333-1.333 1.333H8v1.334C8 22.8 9.2 24 10.667 24H24l5.333 5.334V10.667C29.333 9.2 28.133 8 26.667 8zm-4 6.667V5.334c0-1.467-1.2-2.667-2.667-2.667H5.333a2.675 2.675 0 00-2.666 2.667v17.333L8 17.334h12c1.467 0 2.667-1.2 2.667-2.667z"
            />
        </svg>
    );
}

export default FeedBackIcon;
